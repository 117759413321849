import { useState, useEffect, useMemo, useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Dinero from 'dinero.js';
import { useApolloClient } from '@apollo/client';
import { CreateHoldDocument, CreateHoldInput } from '../../graphql/graphql.ts';

// StyledComponents
import { StyledButton } from '../common/styled/ActionablesJoy.styled.tsx';
import { createFingerPrint } from '../../utils/createFingerPrint.ts';
import { CartContext } from '../providers/cart.tsx';

interface CheckoutBtnProps {
    history: RouteComponentProps['history'];
    btnText: string;
    isAuthenticated: boolean;
    disabled: boolean;
    backgroundcolor: string;
    eventId: string;
    checkoutNotes?: string;
    chargeAmount: number;
    chargeCurrency: string;
}

export const CheckoutBtn = ({
    history,
    btnText,
    disabled,
    backgroundcolor,
    eventId,
    checkoutNotes,
    chargeAmount,
    chargeCurrency,
}: CheckoutBtnProps) => {
    const { cart, setCart, inputtedUnlockCodes } = useContext(CartContext);
    const [disableBtn, setDisableBtn] = useState<boolean>(disabled);
    const [loading, setLoading] = useState<boolean>(false);
    const apollo = useApolloClient();

    useEffect(() => {
        setDisableBtn(disabled);
    }, [disabled]);

    const chargeDinero = useMemo(
        () => Dinero({ amount: chargeAmount, currency: chargeCurrency as Dinero.Currency }),
        [chargeCurrency, chargeAmount]
    );

    const onSubmit = async () => {
        setDisableBtn(true);
        setLoading(true);

        try {
            const fingerprint = await createFingerPrint();
            const payload: CreateHoldInput = {
                fingerprint,
                eventId,
                unlockCodes: inputtedUnlockCodes,
                tickets: cart.quantities,
                addons: cart.addons,
                charged: {
                    amount: chargeDinero.getAmount(),
                    currency: chargeDinero.getCurrency(),
                },
                tosAcceptanceDate: new Date().toISOString(),
            };

            const { data } = await apollo.mutate({
                mutation: CreateHoldDocument,
                variables: {
                    payload,
                },
            });

            if (!data?.createHold) {
                throw Error('Hold creation failed');
            }

            setCart({
                hold: {
                    id: data.createHold.id,
                    expiresAt: data.createHold.expiresAt,
                    visitorId: fingerprint,
                },
                checkoutNotes,
            });

            setDisableBtn(false);
            setLoading(false);
            history.push('/payment');
        } catch (err) {
            console.error(err);
            alert(`Error creating hold for tickets. ${err}`);
            setDisableBtn(false);
            setLoading(false);
        }
    };

    return (
        <StyledButton
            onClick={onSubmit}
            disabled={disableBtn}
            backgroundcolor={backgroundcolor}
            id={'proceed_to_checkout'}
            size="lg"
        >
            {loading ? 'Loading' : btnText}
        </StyledButton>
    );
};
