import { ApolloClient, InMemoryCache, createHttpLink, DefaultOptions, ApolloLink } from '@apollo/client';
import * as Sentry from '@sentry/react';

const httpLink = createHttpLink({
    uri: import.meta.env.VITE_A1_GRAPHQL_GATEWAY,
});

const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
};

const sentryLink = new ApolloLink((operation, forward) => {
    const activeSpan = Sentry.getActiveSpan();
    const rootSpan = activeSpan ? Sentry.getRootSpan(activeSpan) : undefined;

    // Create `sentry-trace` header
    const sentryTraceHeader = rootSpan
        ? Sentry.spanToTraceHeader(rootSpan)
        : undefined;

    // Create `baggage` header
    const sentryBaggageHeader = rootSpan
        ? Sentry.spanToBaggageHeader(rootSpan)
        : undefined;

    operation.setContext(({ headers = {} }) => {
        return {
            ...headers,
            'sentry-trace': sentryTraceHeader,
            'baggage': sentryBaggageHeader,
        }
    })

    return forward(operation);
});

export const gqClient = new ApolloClient({
    link: sentryLink.concat(httpLink),
    cache: new InMemoryCache({
        addTypename: false,
    }),
    defaultOptions,
    ssrMode: typeof window === 'undefined',
});
