import { Theme, createTheme } from '@mui/material/styles';

const theme: Theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 500,
            md: 900,
            lg: 1200,
            xl: 1800,
        },
    },
    palette: {
        primary: {
            light: '#2E7D32',
            main: '#4A7754',
        },
        action: {
            disabledBackground: '#0000001F',
            disabled: '#00000061',
        },
    },
    typography: {
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    },
    components: {
        MuiTypography: {
            defaultProps: {
                fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
            },
        },
    },
});

theme.typography.body1 = {
    fontSize: '14px',
};

theme.typography.body2 = {
    fontSize: '12px',
};

theme.typography.h1 = {
    fontSize: '20px',
    textAlign: 'center',
};

export default theme;
