import {Typography } from '@mui/material';

export default function TermsOfServiceContent() {
    return (
        <>
            <Typography sx={{ fontSize: '24px', fontWeight: '400', marginBottom: '15px' }}>
                AdmitONE TERMS OF SERVICE.
            </Typography>
            <Typography
                gutterBottom
                sx={{ fontSize: '14px', fontWeight: '400', marginBottom: '15px' }}
            >
                Welcome! The Admit One Corporation (“AdmitONE,” “we,” “our” or “us”) is excited
                you’re visiting us. While you’re here, please review the following terms and
                conditions (“Agreement”). This Agreement clarifies our relationship and governs your
                use of our website (“Site”), our online and mobile ticketing system, and any other
                services provided pursuant to this Agreement (collectively with the Site,
                “Services”). Please read this Agreement carefully. By accessing or using the
                Services, you agree to the terms and conditions of this Agreement. Please also read
                our Privacy Policy, which along with any additional terms you agree to pursuant to
                this Agreement or by turning on specific features, are included in this Agreement.
                If you do not agree to this Agreement, you do not have any right to use the
                Services. We may modify this Agreement at any time by posting a notice on the
                Services or by sending you an email. You will be responsible for reviewing and
                becoming familiar with any such modifications. Your use of the Services following
                such notification constitutes your acceptance of the modified terms. If at any time
                you do not agree to this Agreement, you must terminate your use of the Services. You
                will remain liable for any obligations incurred or charges accrued on or before the
                date of termination. PLEASE READ THE BINDING ARBITRATION CLAUSE AND CLASS ACTION
                PROVISIONS BELOW. THESE PROVISIONS AFFECT HOW DISPUTES ARE RESOLVED. Overview.
                offers three types of access to its Services: 1) “Event Organizers” (you are
                organizing, promoting, or managing events via the Services; 2) “Purchasers” (you are
                purchasing tickets via the Services); and 3) “Website Visitors” (you are browsing
                the Site, but are not an Event Organizer or a Purchaser). The General Terms apply to
                everyone who uses our Services. The Event Organizer Terms apply only to Event
                Organizers. Purchaser Terms apply only to Purchasers. Event Organizers may provide
                access to the Services to Event Organizers’ authorized employees and independent
                contractor end users (“Authorized Users”). For clarity, if you are an Event
                Organizer the terms “you” or “your” will include reference to your Authorized Users,
                as applicable. ‍
            </Typography>
            <Typography sx={{ fontSize: '24px', fontWeight: 'Roboto', marginBottom: '15px' }}>
                GENERAL TERMS
            </Typography>
            <Typography
                gutterBottom
                sx={{ fontSize: '14px', fontWeight: '400', marginBottom: '15px' }}
            >
                1. Access to the Services Subject to the terms of this Agreement, we grant you the
                nonexclusive right to access and use the Services for your lawful, internal business
                purposes. Services may include the offering of any materials displayed or performed
                on the Site and related features (including text, data, software, graphics,
                photographs, images, illustrations, audio clips and video clips). We may change,
                suspend or discontinue the Services (including the availability of any feature or
                content) for any reason, at any time. We may also restrict your access to parts or
                all of the Services without notice. We will not be liable to you or any other party
                for any such suspension, modification, discontinuance, or lack of availability of
                the Services, your Data (defined below), or other content. You represent and warrant
                to us that if you are an individual (i.e., not a corporation) you are at least 18
                years of age (or the age of majority in your jurisdiction), and you are legally
                permitted to use the Services. If you are entering into this Agreement on behalf of
                an entity, you represent and warrant that you have the authority to bind such entity
                to this Agreement, in which case the terms “you” or “your” will refer to such
                entity. This Agreement is void where prohibited by law, and the right to use the
                Services is revoked in such jurisdictions. ‍ 2. Intellectual Property Rights As
                between you and us, we (or our licensors) are the sole and exclusive owner, and will
                retain all right, title, and interest in and to the Services. Except as expressly
                authorized, you will not copy or store any portion of the Services other than for
                your personal, noncommercial use. All trademarks, service marks, and trade names
                that appear on the Services are proprietary to us or third parties. You will abide
                by all copyright notices and restrictions contained in the Services. Except as
                provided in this Section, you may not modify, publish, transmit, participate in the
                transfer or sale of, reproduce, create derivative works based on, distribute,
                perform, display, or in any way exploit, any of the Services in whole or in part.
                Additionally, you will not attempt to circumvent any of our technical measures, or
                decompile, reverse engineer, or disassemble the Services. ‍ 3. Copyright In
                accordance with the Digital Millennium Copyright Act (“DMCA”) and other applicable
                law, we have a policy of terminating, in appropriate circumstances and in our sole
                discretion, users who are deemed to be repeat infringers. We may also terminate the
                accounts of any users who infringe any intellectual property rights of others,
                whether or not there is any repeat infringement. If you think anyone has posted
                material to the Services that violates any copyrights or other intellectual property
                rights, then you can notify us via email at legal@admitone.com or via mail at Attn:
                Legal, Mattice Legal, LLC, 6790 Main Street, Suite 140, Buffalo, NY 14221. Upon
                receipt of such complaint, we may take whatever action, in our sole discretion, we
                deem appropriate, in accordance with applicable law (including with the safe harbor
                provisions of the DMCA). Please see 17 U.S.C. §512(c)(3) for notification
                requirements under the DMCA. We may give notice of a claim of copyright infringement
                to users by means of a general notice on the Services, electronic mail, or by
                written communication. ‍ 4. Use on Your Mobile Device Use of the Services may be
                available through our mobile application (“Mobile App”). The Mobile App may not be
                available for and may not properly function with all mobile devices or
                telecommunication providers. You are solely responsible for any mobile charges,
                including data or network charges, you may incur for using the Services. If you
                chose to use the Services through the Mobile App, we grant you a non-exclusive,
                non-transferrable license to download, install, and use a single copy of the Mobile
                App on your personal mobile device to access and use the Services solely as set
                forth in this Agreement. The Mobile App is licensed to you, not sold. ‍ 5. Consent
                to Use of Data For any data (e.g., text, sound, video, image files, or other
                content) you provide us (“Data”), you grant us a non-exclusive, worldwide,
                royalty-free, transferable license to use, modify, reproduce, and display such Data
                (including all related intellectual property rights) to provide and improve the
                Services. You represent and warrant that you: (i) have the right to grant us the
                rights set forth above; (ii) own or possess sufficient license rights in and to the
                Data to permit the use contemplated under this Agreement; and (iii) you will not
                contribute any Data that: (a) infringes any intellectual property right, proprietary
                right, or the privacy or publicity rights of another; (b) is libelous, defamatory,
                obscene, pornographic, harassing, hateful, offensive, or otherwise violates any law;
                (c) contains a virus, trojan horse, worm, or other computer programming routine or
                engine intended to detrimentally interfere with any system, data, or information;
                (d) causes damage to our Services and systems or users in any way, or (e) promotes
                events that otherwise violate the foregoing restrictions. If you disclose any
                personal data in connection with your use of the Services, you represent and warrant
                that you comply with all applicable laws relating to the collection, use, and
                disclosure of such personal data. We may remove any Data or events from the Services
                at any time, for any reason or for no reason at all, though we will make
                commercially reasonable efforts to alert you of such actions as soon as practicable.
                We may, but are not obligated to, monitor Data and remove any Data or content or
                prohibit any use of the Services, if we believe such content or use may be (or is
                alleged to be) in violation of this Agreement or any applicable laws or may impact
                Services delivery. ‍ 6. Submissions; Feedback Where we have specifically invited or
                requested submissions or comments through the Services, we encourage you to submit
                such content for consideration (“User Submissions”). User Submissions remain the
                property of the submitting party. You grant us a non-exclusive, perpetual,
                irrevocable, royalty-free, fully paid-up worldwide, fully sub-licensable right to
                use, reproduce, modify, adapt publish, translate, create derivative works from,
                distribute, perform, and display such content and your name, voice, and/or likeness
                as contained in your User Submission, in any form throughout the world in any media
                or technology, and including the right to sublicense such rights. Any such User
                Submissions are deemed non-confidential. For any feedback relating to the Services
                (“Feedback”) that you provide, you give us, without charge, royalties, or other
                obligation to you, the right to make, have made, create derivative works, use,
                share, and commercialize your Feedback in any way and for any purpose. We will not
                be required to treat any Feedback as confidential, and will not be liable for any
                ideas or incur any liability as a result of any similarities that may appear in
                future Services or operations. ‍ 7. Confidentiality Our communications to you and
                the Services may contain confidential information. Such confidential information may
                include business, technical, financial, or other information that is marked
                confidential or should reasonably be considered confidential based on the
                circumstances surrounding the disclosure. If you receive any such confidential
                information, then you will not disclose it to any third party without our prior
                written consent, nor will you use such confidential information for any purpose
                other than fulfilling your obligations and exercising your rights under this
                Agreement. AdmitONE confidential information does not include information that you
                independently developed, was rightfully given to you by a third party without
                confidentiality obligation, or that becomes public through no fault of your own. You
                may disclose our confidential information when compelled to do so by law if you
                provide reasonable prior notice to us. ‍ 8. Restrictions You may not post or
                transmit, or cause to be posted or transmitted, any communication designed or
                intended to obtain password, account, or private information from any Services user.
                We will not be liable for any failures in the Services or other problems which are
                related to your Data or any equipment or service outside of our control. You will
                not use any part of the Services to violate the security of any computer network,
                crack passwords, or security encryption codes; transfer or store material that is
                deemed threatening or obscene; or engage in any kind of illegal activity. You will
                use the Services only in compliance with all applicable laws (including policies and
                laws related to spamming, privacy, intellectual property, consumer and child
                protection, obscenity, defamation, and export control laws). You are responsible for
                determining whether the Services are suitable for you to use in light of any
                regulations like HIPAA, data protection laws, or other laws. To the extent you offer
                or are promoting products, services, or events that in any way involve or relate to
                cannabis (including cultivating, manufacturing, packaging, labeling, testing,
                marketing, or selling cannabis or cannabis-infused or derived products), you
                represent and warrant as follows with respect to such offerings: (i) you and your
                events are compliant under all applicable federal (except to the extent that such
                federal laws conflict with state, tribal, or local cannabis laws), state, tribal,
                local, or foreign law, including obtaining all licenses, permits, authorizations,
                and approval required to conduct such business in your jurisdiction; (ii) you do not
                market, promote or advertise such offerings or events in any way which targets
                persons under the age of 21; (iii) you do not make any claims that the use of
                cannabis has curative or therapeutic effects or any other health claims, and (iv)
                your event does not offer cannabis or cannabis-infused products for free, for sale
                by the Event Organizer, or as part of the ticket purchase. ‍ 9. Warranty Disclaimer
                You acknowledge that we have no control over, and no duty to take any action
                regarding: what parties you transact with on the Services; which end users gain
                access to the Services; what results you may obtain via the Services or events
                promoted on the Services; what effects the Services and such events may have on you;
                or what actions you may take as a result of having been exposed to the Services or
                such events. We do not manage or control any business or individual that you may
                interact or transact with through the Services (including Event Organizers), and we
                disclaim all liability for any act or omission by such parties. You hereby waive any
                and all legal or equitable rights or remedies you have or may have against us with
                respect to acts and omissions by such entities. You agree that some events may carry
                inherent risk, and by participating in those events, you choose to assume those
                risks voluntarily. For example, some events may carry risk of illness, bodily
                injury, disability, or death, and you freely and willfully assume those risks by
                choosing to participate in those events. The Services may contain, or direct you to
                events or sites containing, information that some people may find offensive or
                inappropriate. We make no representations concerning any content contained in or
                accessed through the Services, and we will not be responsible or liable for the
                accuracy, copyright compliance, legality, or decency of such material. TO THE
                FULLEST EXTENT ALLOWED BY LAW: (i) THE SERVICES ARE PROVIDED ON AN “AS IS” BASIS,
                WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING IMPLIED
                WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, OR
                NON-INFRINGEMENT, AND (ii) WE DISCLAIM ALL LIABILITY OR RESPONSIBILITY FOR THE
                ACCURACY, RELIABILITY, AVAILABILITY, COMPLETENESS, LEGALITY, OR OPERABILITY OF THE
                SERVICES, INCLUDING THE EVENTS PROMOTED OR OTHER MATERIAL ACCESSIBLE THROUGH THE
                SERVICES, AND (iii) BY USING THE SERVICES, YOU ACKNOWLEDGE THAT WE ARE NOT
                RESPONSIBLE OR LIABLE FOR ANY HARM RESULTING FROM: (a) USE OF THE SERVICES; (b)
                DOWNLOADING INFORMATION FROM OR TRANSACTING THROUGH THE SERVICES; (c) THE ACTS OR
                OMISSIONS OF THIRD PARTIES (INCLUDING EVENT ORGANIZERS AND VENUES), (d) UNAUTHORIZED
                DISCLOSURE OF CONTENT, MATERIAL, OR DATA THROUGH THE SERVICES; AND (e) THE INABILITY
                TO ACCESS OR RETRIEVE ANY DATA FROM THE SERVICES, INCLUDING ANY HARM CAUSED BY
                VIRUSES OR ANY SIMILAR DESTRUCTIVE PROGRAM. ‍ 10. Third Party Sites and Services You
                may gain access from the Services to third-party sites on the Internet. Third-party
                sites or services are not within our supervision or control. We make no
                representations or warranties about any such site or resource, and do not endorse
                the products or services offered by third parties. We further disclaim all
                responsibility and liability for content on such websites. Third-party providers of
                ancillary services may require your agreement to additional or different terms prior
                to your use or access of their sites or services. Any such agreement will in no way
                modify this Agreement. ‍ 11. Registration and Security As a condition to using
                Services, you may be required to supply us with certain registration information.
                You will provide us with accurate, complete, and updated registration information.
                You may not share your password or login credentials with anyone. You are
                responsible for: (i) maintaining the confidentiality of such credentials, and (ii)
                all activity under your account. You will immediately notify us of any suspected or
                unauthorized use of your account or any other breach of security you become aware
                of. We may refuse registration of or suspend or terminate any account in our
                discretion, including in the event we reasonably believe such action is necessary to
                protect the security or integrity of the Services or any data thereon. ‍ 12.
                Indemnity You will indemnify and hold us, our directors, officers, employees,
                agents, and representatives harmless, including costs and attorneys’ fees, from any
                claim or demand made by any third party due to or arising out of: (i) your access to
                or use of the Services, (ii) your violation of this Agreement, (iii) your
                infringement, or the infringement by any third party using your account, of any
                intellectual property, or other right of any person or entity, (iv) the Data or any
                other materials provided to us, including any third party claims relating to your
                disclosure of personal data to us, (v) any gross negligence or willful misconduct by
                you or your personnel, (v) any event, including any physical damage or personal harm
                incurred in connection therewith, (vi) any cancellation of an event, (vii) any
                failure to allocate a sufficient number of tickets, (viii) your use of any purchaser
                information, and (iv) our collection and remission of taxes. ‍ 13. App Stores To the
                extent you acquire any part of the Services from a third party application store
                provider (“App Store Provider”), the terms of this Section 13 apply. You agree that
                this Agreement is solely between you and us, and that the App Store Provider has no
                responsibility for the Services. For Apple, your use of the App Store must comply
                with the App Store Terms of Service. You acknowledge that the App Store Provider has
                no obligation to furnish any maintenance and support services. In the event of any
                failure of the Services to conform to any applicable warranty, you may notify Apple
                or the App Store Provider, and, in the case of Apple, Apple will refund the purchase
                price for the Services to you. To the maximum extent permitted by applicable law,
                App Store Provider will have no other warranty obligation whatsoever with respect to
                the Services. You acknowledge that App Store Provider is not responsible for
                addressing any claims of you or any third party relating to the Services or your
                possession and use of the Services, including: (a) product liability claims; (b) any
                claim that the Services fail to conform to any applicable legal or regulatory
                requirement; and (c) claims arising under consumer protection or similar
                legislation. You acknowledge that, in the event of any third-party claim that the
                Services infringe a third party’s intellectual property rights, App Store Provider
                will not be responsible for the investigation, defense, settlement, and discharge of
                any such intellectual property infringement claim. We and you acknowledge that App
                Store Provider, and App Store Provider’s subsidiaries, are third-party beneficiaries
                of this Agreement as relates to your license of the Services, and that, upon your
                acceptance of this Agreement, App Store Provider will have the right to enforce this
                Agreement as it relates to your license of the Services against you as a third-party
                beneficiary thereof. You represent and warrant that (i) you are not located in a
                country that is subject to a U.S. Government embargo, or that has been designated by
                the U.S. Government as a “terrorist supporting” country; and (ii) you are not listed
                on any U.S. Government list of prohibited or restricted parties. ‍ 14. Limitation of
                Liability EXCEPT FOR DAMAGES AND LIABILITIES ARISING FROM: (i) A PARTY’S BREACH OF
                SECTION 6 (CONFIDENTIALITY); (ii) ITS VIOLATION OF APPLICABLE LAWS; (iii) ITS
                INDEMNIFICATION OBLIGATIONS; OR (iv) GROSS NEGLIGENCE OR INTENTIONAL MISCONDUCT; IN
                NO EVENT WILL EITHER PARTY, ITS DIRECTORS, OFFICERS, SHAREHOLDERS, EMPLOYEES,
                MEMBERS, AGENTS, AND REPRESENTATIVES BE LIABLE TO THE OTHER FOR ANY INDIRECT,
                INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND OR DAMAGES FOR LOSS OF
                USE, PROFITS, DATA, IMAGES, OR OTHER INTANGIBLES, OR IN OUR CASE, DAMAGES FOR
                UNAUTHORIZED USE, NON-PERFORMANCE OF THE SERVICES, ERRORS OR OMISSIONS. OUR
                LIABILITY UNDER THIS AGREEMENT WILL BE LIMITED TO THE GREATER OF: (A) THE FEES PAID
                BY YOU, IF ANY, FOR ACCESSING THE DURING THE TWELVE (12) MONTHS PRIOR TO THE DATE
                THE CLAIM AROSE; OR (B) $100.00 US DOLLARS. SOME JURISDICTIONS DO NOT ALLOW THE
                EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
                LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU. ‍ 15. Termination This Agreement
                begins on the date you first use the Services and continues as long as you access
                them or, in the case of account holders, as long as you have an account. You may
                terminate your use of the Services at any time by closing your account; please note
                that you will remain liable for all Services fees incurred prior to termination. We
                may also terminate or suspend any and all Services immediately, without prior notice
                or liability, if you breach any of the terms or conditions of this Agreement. All
                fees paid hereunder are non-refundable, unless otherwise required by law or pursuant
                to the applicable refund policy of the Event Organizer. Upon any such termination,
                your right to use the Services and access Data through the Services will immediately
                cease. All provisions of this Agreement which by their nature should survive
                termination will survive termination, including, without limitation, ownership
                provisions, warranty disclaimers, indemnification obligations, and limitations of
                liability. ‍ 16. Privacy Please review our Privacy Policy, which governs our use of
                personal data. 17. Binding Arbitration and Class Action Waiver. Any dispute under
                this Agreement will be settled by binding individual arbitration before the American
                Arbitration Association ("AAA") under the Federal Arbitration Act (“FAA”). All
                disputes arising under this Agreement shall be governed by and interpreted in
                accordance with the laws of California, without regard to principles of conflict of
                laws. The parties agree not to sue in court in front of a judge or jury. Instead, a
                neutral arbitrator will decide, and the arbitrator’s decision will be final except
                for a limited right of review under the FAA. Class action lawsuits, class-wide
                arbitrations, private attorney-general actions, and any other proceeding where
                someone acts in a representative capacity are not allowed. This Section 17 applies
                to any claim or controversy between the parties, except disputes relating to the
                enforcement or validity of your or your licensors’ or our or our licensors’
                intellectual property rights. This Agreement governs, to the extent of conflict with
                the AAA’s Commercial Arbitration Rules or Consumer Arbitration Rules. The AAA will
                conduct all arbitrations under its Commercial Arbitration Rules (or if the value of
                the dispute is $75,000 or less, the Consumer Arbitration Rules). For more
                information, see www.adr.org or call 1-800-778-7879. Any in-person hearing will take
                place in San Francisco, California. A party must file any claim or dispute (except
                intellectual property disputes) within one year from when it first could be filed.
                Otherwise, it’s permanently barred. If any part of this Section 17 is found to be
                illegal or unenforceable, the remainder will remain in effect (with an arbitration
                award issued before any court proceeding begins), except that if a finding of
                partial illegality or unenforceability would allow class-wide or representative
                arbitration, Section 17 will be unenforceable in its entirety. ‍ 18. Miscellaneous
                The failure of either party to exercise in any respect any right provided for herein
                will not be deemed a waiver of any further rights hereunder. If any provision of
                this Agreement is found to be unenforceable or invalid, that provision will be
                limited or eliminated to the minimum extent necessary so that this Agreement will
                otherwise remain in full force and effect and enforceable. This Agreement is not
                assignable, transferable, or sublicensable by you except with our prior written
                consent. We may transfer, assign or delegate this Agreement and any or all of its
                rights and obligations without consent. If you are purchasing paid Services, we may
                request and with your consent, we may publicly disclose in writing that you
                purchased Services and may use your trademarks, service marks or trade name solely
                in connection with such disclosures. This Agreement (including our Privacy Policy)
                is the complete and exclusive statement of the mutual understanding of the parties
                and supersedes and cancels all previous written and oral agreements, communications
                and other understandings relating to the subject matter of this Agreement. No
                agency, partnership, joint venture, or employment is created as a result of this
                Agreement, and you do not have any authority to bind us in any respect whatsoever.
                Any notice that is required or permitted by this Agreement will be in writing and
                will be deemed effective upon receipt, when sent by confirmed e-mail, if to you, at
                the email address you provide with your account, and if to us, to legal@admitone.com
                or when delivered in person by nationally recognized overnight courier or mailed by
                first class, registered or certified mail, postage prepaid, to:
            </Typography>
        </>
    );
}
