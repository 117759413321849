import { CircleStep, StepBoxContainer } from './styled/HeaderJoy.styled';
import CheckIcon from '@mui/icons-material/Check';
import { Typography } from '@mui/joy';

interface StepCircleProps {
    stepId: number;
    current: number;
    text: string;
    minWidthMD?: string;
    minWidthSM?: string;
    checked: boolean;
    organizerColor: string;
}

export default function HeaderJoyComponentCircle({
    stepId,
    current,
    text,
    minWidthMD,
    minWidthSM,
    organizerColor,
    checked,
}: StepCircleProps) {
    return (
        <StepBoxContainer
            sx={{ minWidth: { md: minWidthMD ?? minWidthMD, xs: minWidthSM ?? minWidthSM } }}
        >
            <CircleStep step={stepId} backgroundcolor={organizerColor} currentstep={current}>
                {checked ? <CheckIcon sx={{ fontSize: 14 }} /> : stepId}
            </CircleStep>
            <Typography
                level="title-sm"
                sx={{fontWeight: 600, color: current === stepId || checked ? organizerColor : 'rgba(159, 166, 173, 1)!important' }}
            >
                {text}
            </Typography>
        </StepBoxContainer>
    );
}
