// Footer.styled.tsx

import { styled } from '@mui/system';

export const StyledFooter = styled('footer')({
    width: '100%',
    backgroundColor: '#fafafa',
    borderTop: `1px solid #DFE3E8`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px 0',
    '& .fc_inner_container': {
        width: '100%',
        maxWidth: '1140px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 20px',
        '@media (max-width: 767px)': {
            flexDirection: 'column',
            textAlign: 'center',
        },
        '& .fc_section': {
            display: 'flex',
            alignItems: 'center',
            '@media (max-width: 767px)': {
                marginBottom: '15px',
            },
        },
        '& .fc_ul': {
            display: 'flex',
            listStyle: 'none',
            padding: 0,
            margin: 0,
            flexDirection: 'row',
            '& .fc_ul_link': {
                marginLeft: '25px',
                color: '#637381',
                textDecoration:`none`,
                '&:hover': {
                    color: '#637381',
                },
                '&:last-child': {
                    marginRight: 0,
                },
            },
            '@media (max-width: 767px)': {
                flexDirection: 'column',
                alignItems: 'center',
                '& li': {
                    marginTop: '15px',
                },
                '& .fc_ul_link': {
                    color: '#637381',
                    textDecoration: 'none',
                    marginLeft:`0px`,
                    '&:hover': {
                        color: '#637381',
                    },
                },
            },
        },
    },
});
