import { useState, useContext, MouseEvent, KeyboardEvent } from 'react';

import { SxProps, FormHelperText } from '@mui/material';
import { Box, Stack, ChipDelete, FormControl, Input, Chip, Theme } from '@mui/joy';
import { StyledIconButtonUnpadded } from '../common/styled/ActionablesJoy.styled';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { CartContext } from '../providers/cart.tsx';

interface CodeToUnlockProps {
    organizerPrimaryColor: string;
}

export default function CodeToUnlockJoy({ organizerPrimaryColor }: CodeToUnlockProps) {
    const { addUnlockCode, removeUnlockCode, inputtedUnlockCodes } = useContext(CartContext);
    const [inputCode, setInputCode] = useState('');
    const [error, setError] = useState('');

    const inputStyle: SxProps = {
        marginBottom: 2,
        paddingRight: 2,
        paddingBlock: 1,
        width: '100%',
        maxWidth: '367px',
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor:
                    organizerPrimaryColor !== '#4A7754'
                        ? '#212B36 !important'
                        : organizerPrimaryColor,
            },
        },
        '&:focus-within::before': {
            boxShadow: `inset 0px 0px 1px 1px ${organizerPrimaryColor}!important`,
        },
    };

    const applyCode = (e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (inputCode?.trim().length === 0) {
            return;
        }

        const result = addUnlockCode(inputCode);

        if (result !== '') {
            setError(result as string);
            setTimeout(() => {
                setError('');
            }, 5000);
        } else {
            setInputCode('');
        }
    };

    return (
        <Stack
            sx={{
                marginBottom: 2,
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
                <FormControl
                    id="code-to-unlock-control"
                    size="md"
                    color="neutral"
                    sx={{ width: '100%' }}
                >
                    <Input
                        color="neutral"
                        sx={{ ...inputStyle, paddingRight: 2 }}
                        size="md"
                        id="code-to-unlock"
                        placeholder="Unlock with Code"
                        name="code-to-unlock"
                        error={error !== ''}
                        onChange={(e) => setInputCode(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                applyCode(e);
                            }
                        }}
                        fullWidth
                        value={inputCode}
                        endDecorator={
                            <StyledIconButtonUnpadded
                                id={'unlock-code-action'}
                                size="sm"
                                sx={{ borderRadius: `${(theme: Theme) => theme.radius['sm']}` }}
                                backgroundcolor={organizerPrimaryColor}
                                onClick={applyCode}
                            >
                                <ArrowForwardIcon sx={{ width: 20, height: 20 }} />
                            </StyledIconButtonUnpadded>
                        }
                        variant="outlined"
                    />
                    {error && (
                        <FormHelperText error={error != ''} sx={{ mb: 2 }}>
                            {error}
                        </FormHelperText>
                    )}
                </FormControl>
            </Box>
            <Box>
                {inputtedUnlockCodes.map((code) => {
                    return (
                        <Chip
                            key={code}
                            sx={{ marginRight: 1 }}
                            size={'md'}
                            variant="soft"
                            color="neutral"
                            endDecorator={<ChipDelete onDelete={() => removeUnlockCode(code)} />}
                        >
                            {code}
                        </Chip>
                    );
                })}
            </Box>
        </Stack>
    );
}
