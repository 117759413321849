import { createContext, FC, useState } from 'react';
import { SafeChargeInstance } from '../../types/safeCharge';
import { NUVEI_ENV, NUVEI_MERCHANT_ID, NUVEI_SITE_ID } from '../../config';


export const SafeChargeContext = createContext<SafeChargeInstance|null>(null);

export const SafeChargeProvider: FC<React.PropsWithChildren> = ({ children }) => {
    const [safeCharge] = useState(
        window.SafeCharge({
            env: NUVEI_ENV || 'int',
            merchantId: NUVEI_MERCHANT_ID,
            merchantSiteId: NUVEI_SITE_ID,
        })
    );

    return <SafeChargeContext.Provider value={safeCharge}>{children}</SafeChargeContext.Provider>;
};
