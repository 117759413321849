import { useEffect, useMemo } from 'react';
import { Route, withRouter, BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { ApolloProvider } from '@apollo/client';
import { gqClient } from './config/graphqlClient';

// Pages
import NoMatch from './pages/404';

// Components
import Footer from './components/footer/Footer.component.tsx';

// Theme
import theme from './styles/theme.styles';
import OrderSummaryPage from './pages/OrderSummaryPage';
import PaymentPage from './pages/PaymentPage';

// Variables
import { EVENTS_URL } from './config';
import ScrollToTop from './components/ScrollToTop.component.tsx';
import { ConfirmationPage } from './pages/ConfirmationPage.tsx';
import ErrorBoundary from './components/ErrorBoundary.tsx';
import { SafeChargeProvider } from './components/common/safecharge.provider.tsx';
import { CartProvider } from './components/providers/cart.tsx';

export function useQueryString() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

function RedirectToEvents() {
    useEffect(() => {
        window.location.href = EVENTS_URL;
    }, []);

    return null;
};

function App() {
    return (
        <>
            <div style={{ minHeight: 'calc(100vh - 82.4px' }}>
                <ScrollToTop />
                <Switch>
                    <Route exact path="/" component={RedirectToEvents} />
                    <Route exact path="/payment" component={PaymentPage} />
                    <Route exact path="/confirmation" component={ConfirmationPage} />
                    <Route exact path="/404" component={NoMatch} />
                    <Route exact path="/:id" component={OrderSummaryPage} />
                </Switch>
            </div>
            <Footer />
        </>
    );
}

const AppWithRouterAccess = withRouter(App);

const RouterApp = () => {
    return (
        <Router>
            <SafeChargeProvider>
                <ApolloProvider client={gqClient}>
                    <CartProvider>
                        <ThemeProvider theme={theme}>
                            <ErrorBoundary>
                                <AppWithRouterAccess />
                            </ErrorBoundary>
                        </ThemeProvider>
                    </CartProvider>
                </ApolloProvider>
            </SafeChargeProvider>
        </Router>
    );
};

export default RouterApp;
