import { object, string, size, refine, nullable, pattern, trimmed } from 'superstruct';

import { isValidEmail } from '../utils/validators';

const LENGTH_MIN = 1;
const LENGTH_MAX_NAME = 50;
const EMOJI_PATTERN = /[\u{1F000}-\u{1F6FF}]|[\u{1F900}-\u{1F9FF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{1F1E0}-\u{1F1FF}]|[\u{1F900}-\u{1F9FF}]|[\u{1FA70}-\u{1FAFF}]|[\u{2194}-\u{2199}]|[\u{2B05}-\u{2B07}]|[\u{2934}-\u{2935}]|[\u{3030}]|[\u{303D}]|[\u{3297}]|[\u{3299}]|\u{FE0F}|\u{20E3}|[\u{E0020}-\u{E007F}]|[\u{1F9B0}-\u{1F9B3}]/gu;

export const conditionalPaymentValidationSchema = (requireAddress?: boolean) => object({
    firstName: refine(size(string(), LENGTH_MIN, LENGTH_MAX_NAME), 'firstName', (value) => {
        switch (true) {
            case value === '':
            case value.length < LENGTH_MIN:
                return 'First name is required.';
            case EMOJI_PATTERN.test(value):
                return 'First name cannot contain emojis.'
            default:
                return true;
        }
    }),
    firstNameConfirm: string(),
    lastName: refine(size(string(), LENGTH_MIN, LENGTH_MAX_NAME), 'lastName', (value) => {
        switch (true) {
            case value === '':
            case value.length < LENGTH_MIN:
                return 'Last Name is required.';
            case EMOJI_PATTERN.test(value):
                return 'Last name cannot contain emojis.'
            default:
                return true;
        }
    }),
    email: refine(pattern(trimmed(string()), /^\S+@\S+$/i), 'Email', (value) => {
        switch (true) {
            case value === '':
                return 'Email is required.';
            case !isValidEmail(value):
                return 'A valid Email is required.';
            default:
                return true;
        }
    }),
    emailConfirmation: refine(
        pattern(trimmed(string()), /^\S+@\S+$/i),
        'Email',
        (value, parent) => {
            switch (true) {
                case value === '':
                case value.length < LENGTH_MIN:
                    return 'Email Confirmation is required.';
                case !isValidEmail(value):
                    return 'A valid Email is required.';
                case parent.branch[0].email !== value:
                    return 'Emails do not match';

                default:
                    return true;
            }
        }
    ),
    phoneNumber: refine(size(string(), LENGTH_MIN, 15), 'email', (value) => {
        switch (true) {
            case value === '':
            case value.length < LENGTH_MIN:
                return 'Phone Number is required.';
            default:
                return true;
        }
    }),
    streetAddress: requireAddress
        ? refine(size(string(), LENGTH_MIN, 100), 'streetAddress', (value) => {
            switch (true) {
                case value === '':
                case value.length < LENGTH_MIN:
                    return 'Address is required.';
                default:
                    return true;
            }
        })
        : nullable(string()),
    city: requireAddress
        ? refine(size(string(), LENGTH_MIN, 100), 'city', (value) => {
            switch (true) {
                case value === '':
                case value.length < LENGTH_MIN:
                    return 'City is required.';
                default:
                    return true;
            }
        })
        : nullable(string()),
    country: refine(string(), 'country', (value) => {
        if (value?.length < 2) {
            return 'Country is required.';
        } else {
            return true;
        }
    }),
    province: refine(string(), 'province', (value, context) => {
        if (context.branch[0].country === 'CA' || context.branch[0].country === 'US') {
            if (typeof value !== 'string' || value.trim().length === 0) {
                return 'Province / State is required.';
            }
        }
        return true;
    }),
    zipCode: refine(string(), 'zipCode', (value, context) => {
        if (requireAddress && value?.trim()?.length < 1) {
            return 'Postal/Zip Code is required.';
        }

        if (context.branch[0].country === 'CA' || context.branch[0].country === 'US') {
            if (typeof value !== 'string' || value.trim().length === 0) {
                return 'Zip Code is required.';
            }
        }
        return true;
    }),
});
