import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './styles/fonts.css';
import './styles/setup.css';

import * as Sentry from '@sentry/react';

const env = import.meta.env.VITE_SENTRY_ENV || 'development';

Sentry.init({
    dsn: 'https://9ae457399d399b5e10a358929b13c556@o1412149.ingest.sentry.io/4506535918764032',
    tracePropagationTargets: [
        'localhost',
        /^https:\/\/checkout\.admitone\.com/,
        /^https:\/\/a1-client-web-checkout-dev\.vercel\.app/,
        /^https:\/\/graphql\.admitone\.com/,
    ],
    integrations: [
        Sentry.reactRouterV5BrowserTracingIntegration({ history }),
        Sentry.replayIntegration({
            maskAllText: true,
            blockAllMedia: true,
        }),
    ],
    environment: env,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

(async () => {
    ReactDOM.createRoot(document.getElementById('root')!).render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
})();
