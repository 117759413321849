import { Dispatch, SetStateAction, useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { SubmitHandler, useForm } from 'react-hook-form';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { conditionalPaymentValidationSchema } from '../../types/formValidators';
import { PaymentFormType } from '../../types/forms';
import { SafeChargeCardFieldType } from '../../types/safeCharge';

import {
    Box,
    Typography,
    Input,
    Select,
    Option,
    FormControl,
    FormHelperText,
    FormLabel,
    Modal,
    Sheet,
    CircularProgress,
} from '@mui/joy';
import { Controller } from 'react-hook-form';
import { StyledButton } from '../common/styled/ActionablesJoy.styled';

import { margin2, margin3 } from '../../utils/sharedStyleVariables';
import { SafeChargeCC } from '../orderSummary/safeCharge.component';
import { CountryRegionData } from 'react-country-region-selector';
import { useHistory } from 'react-router-dom';
import {
    CreateCheckoutSessionDocument,
    CreateInternalOrderInput,
    CreateOrderDocument,
    PaymentPageEventQuery,
} from '../../graphql/graphql';
//import { sendPurchaseDataToAnalytics } from '../../utils/googleTagManager.ts';
import { useApolloClient } from '@apollo/client';
import { SafeChargeContext } from '../common/safecharge.provider';
import { CartContext } from '../providers/cart.tsx';

interface PaymentFormProps {
    setTermsOfServiceShowing: Dispatch<SetStateAction<boolean>>;
    organizerPrimaryColor: string;
    event: PaymentPageEventQuery['event'];
    setErrorAlert: (err: string) => void;
    total: number;
}

export type paymentFieldType = {
    cardHN: string;
};

//language=GraphQL
export default function PaymentForm({
    setErrorAlert,
    organizerPrimaryColor,
    event,
    total,
}: PaymentFormProps) {
    const history = useHistory();
    const safeCharge = useContext(SafeChargeContext);

    const [paymentFields, setPaymentFields] = useState<paymentFieldType>({
        cardHN: '',
    });
    const [safeChargeCardField, setSafeChargeCardField] = useState<SafeChargeCardFieldType | null>(
        null
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [idempotencyKey, setIdempotencyKey] = useState(uuidv4());
    const { cart, setProcessedOrderId } = useContext(CartContext);

    const apollo = useApolloClient();

    const methods = useForm({
        defaultValues: {
            firstName: '',
            firstNameConfirm: '',
            lastName: '',
            streetAddress: '',
            city: '',
            country: '',
            province: '',
            zipCode: '',
            phoneNumber: '',
            email: '',
            emailConfirmation: '',
        },
        resolver: superstructResolver(
            conditionalPaymentValidationSchema(event?.requirePurchaserAddress)
        ),
        mode: 'onBlur',
    });

    const {
        register,
        handleSubmit,
        control,
        setValue,
        formState: { errors },
        watch,
    } = methods;

    const country = watch('country');

    const handleSubmitPayment: SubmitHandler<PaymentFormType> = async (data) => {
        setLoading(true);

        try {
            if (!cart?.hold?.id) {
                return displayError('No precheckout hold found. Please try again.');
            }

            if (data.firstNameConfirm !== '') {
                return displayError('Failed to submit form (Error Code: 307).');
            }

            if (total > 0 && (paymentFields.cardHN === '' || !safeChargeCardField)) {
                return displayError('Card information needs to be filled out.');
            }

            if (
                data.country === 'CA' &&
                !/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(data.zipCode.trim())
            ) {
                return displayError('Invalid Canadian postal code.');
            }

            if (data.country === 'US' && !/^\d{5}(-\d{4})?$/.test(data.zipCode.trim())) {
                return displayError('Invalid US zip code.');
            }

            // Get Payment information if there
            let paymentRequest = null;

            if (safeChargeCardField && safeCharge) {
                const sessionResponse = await apollo.mutate({
                    mutation: CreateCheckoutSessionDocument,
                });

                if (!sessionResponse.data?.createCheckoutSession?.sessionToken) {
                    return displayError('Failed to Create a Checkout Session');
                }

                const res = await safeCharge.getToken(safeChargeCardField, {
                    sessionToken: sessionResponse.data.createCheckoutSession.sessionToken,
                    cardHolderName: paymentFields.cardHN,
                    billingAddress: {
                        firstName: data.firstName,
                        lastName: data.lastName,
                        email: data.email,
                        country: data.country,
                    },
                });

                if (res.status === 'SUCCESS') {
                    paymentRequest = {
                        nonce: res.ccTempToken,
                        sessionToken: sessionResponse.data.createCheckoutSession.sessionToken,
                        clientRequestId: idempotencyKey,
                        cardHolderName: paymentFields.cardHN,
                    };
                }

                if (res.error) {
                    window.scrollTo(0, 0);
                    if (res.error.id === 'incomplete_card_number') {
                        displayError('Please complete payment fields in order to proceed');
                    }

                    if (res.error.id === 'incomplete_date') {
                        displayError('Please complete expiry date');
                    }

                    if (res.error.id === 'incomplete_cvc') {
                        displayError('Please submit a complete CVV number');
                    }

                    displayError('Payment declined. Please try another card');

                    return;
                }

                if (res.status === 'ERROR' && res.error.type === 'card') {
                    displayError(
                        'Credit card company not supported by processor, please try a different card'
                    );
                    return;
                }
            }

            const body: CreateInternalOrderInput = {
                profile: {
                    firstName: data.firstName,
                    firstNameConfirm: data.firstNameConfirm,
                    lastName: data.lastName,
                    email: data.email,
                    phone: data.phoneNumber,
                    streetAddress: data.streetAddress,
                    city: data.city,
                    province: data.province,
                    postalCode: data.zipCode,
                    country: data.country,
                },
                hold: {
                    id: cart.hold.id,
                    fingerprint: cart?.hold?.visitorId as string,
                },
                idempotencyKey,
                referral: cart?.referralCodes?.[0] ?? null,
                paymentRequest,
            };

            const response = await apollo.mutate({
                mutation: CreateOrderDocument,
                variables: {
                    payload: body,
                },
            });

            if (response?.data?.createInternalOrder) {
                setProcessedOrderId(response.data.createInternalOrder);
                history.push('/confirmation');
            }
        } catch (error) {
            console.error((error as Error)?.message);
            window.scrollTo(0, 0);

            if ((error as Error)?.message.toLowerCase().includes('payment declined')) {
                setIdempotencyKey(uuidv4());
            }

            setErrorAlert(`Error: ${(error as Error)?.message}`);
        } finally {
            setLoading(false);
        }
    };

    const displayError = (text: string) => {
        window.scrollTo(0, 0);
        setErrorAlert(text);
    };

    const canadaData = CountryRegionData.find(([countryName]) => countryName === 'Canada');
    const usData = CountryRegionData.find(([countryName]) => countryName === 'United States');
    const otherCountriesData = CountryRegionData.filter(
        ([countryName]) => countryName !== 'Canada' && countryName !== 'United States'
    );
    const orderedCountryData = [canadaData!, usData!, ...otherCountriesData];

    const inputStyle = {
        '&:focus-within::before': {
            boxShadow: `inset 0px 0px 1px 1px ${organizerPrimaryColor}!important`,
        },
    };

    const requiredSymbol = () => {
        return <span style={{ color: 'rgba(196, 28, 28, 1)' }}>*</span>;
    };

    return (
        <Box sx={{ flex: '1' }}>
            <form onSubmit={handleSubmit(handleSubmitPayment)}>
                <Box>
                    <Typography
                        level={'body-lg'}
                        sx={{
                            fontSize: 24,
                            mb: margin2,
                            textAlign: 'left',
                            fontWeight: 600,
                        }}
                    >
                        Your Information
                    </Typography>
                </Box>
                <Typography
                    level={'body-md'}
                    sx={{
                        mb: margin3,
                        textAlign: 'left',
                        fontWeight: 600,
                    }}
                >
                    Full name
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 2,
                        marginBottom: 3,
                        flexDirection: { md: 'row', sm: 'column', xs: 'column' },
                    }}
                >
                    {/* First Name */}
                    <Box>
                        <FormControl error id="firstName" sx={{ marginBottom: 1 }}>
                            <>
                                <FormLabel sx={{ fontWeight: 500 }}>
                                    First Name{requiredSymbol()}
                                </FormLabel>
                                <Input
                                    color="neutral"
                                    placeholder="First Name"
                                    size="md"
                                    fullWidth
                                    sx={{ ...inputStyle }}
                                    {...register('firstName')}
                                    error={!!errors.firstName}
                                />
                                {errors.firstName && (
                                    <FormHelperText sx={{ color: 'red', mx: 1 }}>
                                        {errors?.firstName?.message ? errors.firstName.message : ''}
                                    </FormHelperText>
                                )}
                            </>
                        </FormControl>
                    </Box>
                    {/* Last Name */}
                    <Box sx={{ flex: 1 }}>
                        <FormControl error id="lastName" sx={{ width: '100%', marginBottom: 1 }}>
                            <>
                                <FormLabel sx={{ fontWeight: 500 }}>
                                    Last Name{requiredSymbol()}
                                </FormLabel>
                                <Input
                                    color="neutral"
                                    placeholder="Last Name"
                                    size="md"
                                    fullWidth
                                    sx={{ ...inputStyle }}
                                    {...register('lastName')}
                                    error={!!errors.lastName}
                                />
                                {errors.lastName && (
                                    <FormHelperText sx={{ color: 'red', mx: 1 }}>
                                        {errors?.lastName?.message ? errors.lastName.message : ''}
                                    </FormHelperText>
                                )}
                            </>
                        </FormControl>
                    </Box>
                </Box>

                <Typography
                    level={'body-md'}
                    sx={{
                        mt: margin3,
                        mb: margin3,
                        textAlign: 'left',
                        fontWeight: 600,
                    }}
                >
                    Address
                </Typography>
                {/* Street Address*/}
                <Box sx={{ marginBottom: 2 }}>
                    <FormControl error id="streetAddress" sx={{ width: '100%', marginBottom: 1 }}>
                        <>
                            <FormLabel sx={{ fontWeight: 500 }}>
                                Street Address
                                {event?.requirePurchaserAddress ? requiredSymbol() : ''}
                            </FormLabel>
                            <Input
                                color="neutral"
                                placeholder="Street Address"
                                size="md"
                                fullWidth
                                sx={{ ...inputStyle }}
                                {...register('streetAddress')}
                                error={!!errors.streetAddress}
                            />
                            {errors.streetAddress && (
                                <FormHelperText sx={{ color: 'red', mx: 1 }}>
                                    {errors?.streetAddress?.message
                                        ? errors.streetAddress.message
                                        : ''}
                                </FormHelperText>
                            )}
                        </>
                    </FormControl>
                </Box>

                {/* Town City */}
                <Box sx={{ marginBottom: 2 }}>
                    <FormControl error id="city" sx={{ width: '100%', marginBottom: 1 }}>
                        <>
                            <FormLabel sx={{ fontWeight: 500 }}>
                                Town / City{event?.requirePurchaserAddress ? requiredSymbol() : ''}
                            </FormLabel>
                            <Input
                                color="neutral"
                                placeholder="City"
                                size="md"
                                fullWidth
                                sx={{ ...inputStyle }}
                                {...register('city')}
                                error={!!errors.city}
                            />
                            {errors.city && (
                                <FormHelperText sx={{ color: 'red', mx: 1 }}>
                                    {errors?.city?.message ? errors.city.message : ''}
                                </FormHelperText>
                            )}
                        </>
                    </FormControl>
                </Box>
                {/* Addresss block grid..................... */}
                <Box sx={{ marginBottom: 2 }}>
                    <FormControl error id="country" sx={{ marginBottom: 1 }}>
                        <FormLabel>Country{requiredSymbol()}</FormLabel>
                        <Controller
                            name="country"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    id="country"
                                    color="neutral"
                                    placeholder="Country"
                                    size="md"
                                    value={watch('country')}
                                    defaultValue={''}
                                    onChange={(_, newValue) => {
                                        setValue('country', newValue as string);
                                    }}
                                >
                                    <Option value="">--Select Country--</Option>
                                    {orderedCountryData.map(([countryName, countryCode]) => (
                                        <Option key={countryCode} value={countryCode}>
                                            {countryName}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        />
                        {errors.country && errors.country.message && (
                            <FormHelperText sx={{ color: 'red' }}>
                                {errors.country.message}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 2,
                        marginBottom: 3,
                        flexDirection: { md: 'row', sm: 'column', xs: 'column' },
                    }}
                >
                    <Box sx={{ marginBottom: 2, flex: 1 }}>
                        <FormControl error id="province" sx={{ marginBottom: 1 }}>
                            <FormLabel>
                                {country === 'US' ? (
                                    <>State{requiredSymbol()}</>
                                ) : country === 'CA' ? (
                                    <>Province{requiredSymbol()}</>
                                ) : (
                                    <>
                                        State/Province
                                        {event?.requirePurchaserAddress ? requiredSymbol() : ''}
                                    </>
                                )}
                            </FormLabel>
                            <Controller
                                name="province"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        id="province"
                                        color="neutral"
                                        placeholder="Province"
                                        sx={{ width: '100%' }}
                                        size="md"
                                        value={watch('province')}
                                        defaultValue={''}
                                        onChange={(_, newValue) => {
                                            setValue('province', newValue as string);
                                        }}
                                    >
                                        <Option value="">--Select Province/State--</Option>

                                        {country !== '' &&
                                            (country !== undefined || true) &&
                                            // @ts-expect-error - For now.
                                            CountryRegionData.find(
                                                ([, countryCode]) => countryCode === country
                                            )[2]

                                                .split('|')
                                                .map((region) => {
                                                    const [name] = region.split('~');
                                                    return (
                                                        <Option key={name} value={name}>
                                                            {name}
                                                        </Option>
                                                    );
                                                })}
                                    </Select>
                                )}
                            />
                            {errors.province && errors.province.message && (
                                <FormHelperText sx={{ color: 'red' }}>
                                    {errors.province.message}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Box>
                    {/* Zip */}
                    <Box sx={{ marginBottom: 2, flex: 1 }}>
                        <FormControl error id="zipCode" sx={{ width: '100%', marginBottom: 1 }}>
                            <>
                                <FormLabel sx={{ fontWeight: 500 }}>
                                    Postal Code/ZIP Code
                                    {country === 'US' ||
                                    country === 'CA' ||
                                    event?.requirePurchaserAddress
                                        ? requiredSymbol()
                                        : ''}
                                </FormLabel>
                                <Input
                                    color="neutral"
                                    placeholder={`Postal Code/ZIP Code`}
                                    size="md"
                                    fullWidth
                                    sx={{ ...inputStyle }}
                                    {...register('zipCode')}
                                    error={!!errors.zipCode}
                                />
                                {errors.zipCode && (
                                    <FormHelperText sx={{ color: 'red', mx: 1 }}>
                                        {errors?.zipCode?.message ? errors.zipCode.message : ''}
                                    </FormHelperText>
                                )}
                            </>
                        </FormControl>
                    </Box>
                </Box>

                <Typography
                    level={'body-md'}
                    sx={{
                        mb: margin3,
                        textAlign: 'left',
                        fontWeight: 600,
                    }}
                >
                    Contact Information
                </Typography>
                {/* Phone */}
                <Box sx={{ marginBottom: 2 }}>
                    <FormControl error id="phoneNumber" sx={{ width: '100%', marginBottom: 1 }}>
                        <>
                            <FormLabel sx={{ fontWeight: 500 }}>
                                Phone Number{requiredSymbol()}
                            </FormLabel>
                            <Input
                                color="neutral"
                                placeholder="Phone Number"
                                size="md"
                                fullWidth
                                sx={{ ...inputStyle }}
                                {...register('phoneNumber')}
                                error={!!errors.phoneNumber}
                            />
                            {errors.phoneNumber && (
                                <FormHelperText sx={{ color: 'red', mx: 1 }}>
                                    {errors?.phoneNumber?.message ? errors.phoneNumber.message : ''}
                                </FormHelperText>
                            )}
                        </>
                    </FormControl>
                </Box>
                {/* Email */}
                <Box sx={{ marginBottom: 2 }}>
                    <FormControl error id="email" sx={{ width: '100%', marginBottom: 1 }}>
                        <>
                            <FormLabel sx={{ fontWeight: 500 }}>Email{requiredSymbol()}</FormLabel>
                            <Input
                                color="neutral"
                                placeholder="Email"
                                size="md"
                                fullWidth
                                sx={{ ...inputStyle }}
                                {...register('email')}
                                error={!!errors.email}
                            />
                            {errors.email && (
                                <FormHelperText sx={{ color: 'red', mx: 1 }}>
                                    {errors?.email?.message ? errors.email.message : ''}
                                </FormHelperText>
                            )}
                        </>
                    </FormControl>
                </Box>
                {/* Email Confirm */}
                <Box>
                    <FormControl
                        error
                        id="emailConfirmation"
                        sx={{ width: '100%', marginBottom: 1 }}
                    >
                        <>
                            <FormLabel sx={{ fontWeight: 500 }}>
                                Confirm Email{requiredSymbol()}
                            </FormLabel>
                            <Input
                                color="neutral"
                                placeholder="Confirm Email"
                                size="md"
                                fullWidth
                                sx={{ ...inputStyle }}
                                {...register('emailConfirmation')}
                                error={!!errors.emailConfirmation}
                            />
                            {errors.emailConfirmation && (
                                <FormHelperText sx={{ color: 'red', mx: 1 }}>
                                    {errors?.emailConfirmation?.message
                                        ? errors.emailConfirmation.message
                                        : ''}
                                </FormHelperText>
                            )}
                        </>
                    </FormControl>
                </Box>

                <Box
                    sx={{
                        opacity: 0,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: 0,
                        width: 0,
                        zIndex: -1,
                    }}
                >
                    <input
                        tabIndex={-1}
                        id={`field_${idempotencyKey}`}
                        {...register('firstNameConfirm')}
                        required={false}
                    />
                </Box>

                {total > 0 && (
                    <SafeChargeCC
                        paymentFields={paymentFields}
                        setSafeChargeCardField={setSafeChargeCardField}
                        setPaymentFields={setPaymentFields}
                        organizerPrimaryColor={organizerPrimaryColor}
                    />
                )}
                <Box>
                    {/* Submit button................. */}
                    <StyledButton
                        id={'place_order_button'}
                        disabled={loading}
                        type="submit"
                        backgroundcolor={organizerPrimaryColor}
                        sx={{
                            marginTop: 3,
                            width: '100%',
                        }}
                    >
                        Place Order
                    </StyledButton>
                </Box>
            </form>
            <Modal
                aria-labelledby="alert-dialog-title"
                aria-describedby="modal-alert-dialog-description"
                open={loading}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{ maxWidth: 500, borderRadius: 'md', p: 3, boxShadow: 'lg' }}
                >
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="title-lg"
                        textColor="inherit"
                        sx={{ mb: 2 }}
                    >
                        {'Processing...'}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <CircularProgress sx={{ color: organizerPrimaryColor }} />
                    </Box>
                    <Typography id="alert-dialog-description" level="body-lg">
                        We are processing your order. Please do not close or refresh this browser
                        tab.
                    </Typography>
                </Sheet>
            </Modal>
        </Box>
    );
}
