import { useState } from 'react';
import { Typography,Box } from '@mui/joy';
import { StyledActionButton } from '../common/styled/ActionablesJoy.styled';
import parse from 'html-react-parser';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';


const ProductDescription = ({ description, organizerPrimaryColor, truncationLength = 250, truncationString = '...' }: { description: string, organizerPrimaryColor: string, truncationLength?: number, truncationString?: string }) => {
    const [isTruncated, setIsTruncated] = useState(true);

    const toggleTruncate = () => {
        setIsTruncated(!isTruncated);
    };

    const truncatedDescription =
        description.length > truncationLength ? description.slice(0, truncationLength) + truncationString : description;

    return (
        <Box sx={{ marginTop: '10px', width: { sm: '75%' } }}>
            <Typography
                sx={{
                    color: 'rgba(0,0,0,0.6)',
                    whiteSpace: 'pre-wrap',
                    lineBreak: 'normal',
                    lineHeight: '143%',
                    letterSpacing: '0.17px',
                    marginBottom:1,
                }}
            >
                {isTruncated ? parse(truncatedDescription) : parse(description)}
            </Typography>
            {description.length > truncationLength && (
                <StyledActionButton
                    variant='plain'
                    onClick={toggleTruncate}
                    setcolor={organizerPrimaryColor}
                    endDecorator={isTruncated ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                >
                    {isTruncated ? 'See more' : 'See less'}
                </StyledActionButton>
            )}
        </Box>
    );
};

export default ProductDescription;
