import { Box, Container, Grid, Skeleton, Stack } from '@mui/material';
import { margin2 } from '../../utils/sharedStyleVariables';
import { StyledHR } from '../common/styled/StaticElements.styled';

export default function ConfirmationPageLoading() {
    return (
        <>
            <Container sx={{ py: '60px' }}>
                <header>
                    <Box sx={{ mb: margin2 }}>
                        <Skeleton width={194} height={17} variant="rectangular" animation="wave" />
                    </Box>
                    <Box sx={{ mb: '40px', display: 'flex', alignItems: 'center' }}>
                        <Skeleton
                            width="100%"
                            height={25}
                            variant="rectangular"
                            animation="wave"
                            sx={{ mb: '30px' }}
                        />
                    </Box>
                </header>
                <Box sx={{ mt: '40px', mb: '60px' }}>
                    <Skeleton width={325} height={93} variant="rectangular" animation="wave" />
                </Box>
                <Box sx={{ mb: '50px' }}>
                    <Skeleton width="100%" height={24} variant="rectangular" animation="wave" />
                </Box>
                <Box sx={{ mb: '30px' }}>
                    <Skeleton width={225} height={32} variant="rectangular" animation="wave" />
                </Box>
                <Box sx={{ mb: '10px' }}>
                    <Skeleton width={400} height={28} variant="rectangular" animation="wave" />
                </Box>
                <Box sx={{ mb: '30px' }}>
                    <Skeleton width={400} height={28} variant="rectangular" animation="wave" />
                </Box>
                <StyledHR />
                <Grid container spacing={2}>
                    <Grid item lg={2} sm={12} xs={12}>
                        <Skeleton width={172} height={197} variant="rectangular" animation="wave" />
                    </Grid>
                    <Grid item lg={10} sm={12} xs={12}>
                        <Stack>
                            <Skeleton
                                width="100%"
                                height={32}
                                variant="rectangular"
                                animation="wave"
                                sx={{ mb: '15px' }}
                            />
                            <Skeleton
                                width={100}
                                height={24}
                                variant="rectangular"
                                animation="wave"
                            />
                            <Skeleton
                                width={125}
                                height={24}
                                variant="rectangular"
                                animation="wave"
                                sx={{ mb: '15px' }}
                            />
                            <Skeleton
                                width={100}
                                height={24}
                                variant="rectangular"
                                animation="wave"
                            />
                            <Skeleton
                                width={150}
                                height={24}
                                variant="rectangular"
                                animation="wave"
                                sx={{ mb: '15px' }}
                            />
                            <Skeleton
                                width={125}
                                height={24}
                                variant="rectangular"
                                animation="wave"
                            />
                        </Stack>
                    </Grid>
                </Grid>
                <StyledHR />
                <Box sx={{ my: '40px', display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Skeleton
                            width={150}
                            height={24}
                            variant="rectangular"
                            animation="wave"
                            sx={{ mr: '15px' }}
                        />
                        <Skeleton
                            width={150}
                            height={24}
                            variant="rectangular"
                            animation="wave"
                            sx={{ mr: '15px' }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <Skeleton
                            width={150}
                            height={34}
                            variant="rectangular"
                            animation="wave"
                            sx={{ mr: '15px' }}
                        />
                        <Skeleton
                            width={150}
                            height={34}
                            variant="rectangular"
                            animation="wave"
                            sx={{ mr: '15px' }}
                        />
                    </Box>
                </Box>
            </Container>
        </>
    );
}
