// colors........
export const organizerPrimaryColor = 'green';
export const organizerSecondaryColor = 'grey';
export const colorGreenMain = '#4A7754';

// margin..........
export const margin1 = '60px';
export const margin2 = '30px';
export const margin3 = '15px';

export const inputMargin1 = '10px';

// font sizes......
export const font1 = '60px';
export const font2 = '34px';
export const font3 = '20px';
export const font4 = '16px';

// element height
export const inputHeight1 = '45px';
export const inputHeight2 = '20px';
