// Components
import PaymentLoading from '../components/payment/paymentLoading.component';
import PaymentLoaded from '../components/payment/paymentLoaded.component';

import { useQuery } from '@apollo/client';
import { EVENTS_URL } from '../config';
import { PaymentPageEventDocument } from '../graphql/graphql';
import { ThemeProvider } from '../ThemeProvider';
import { useContext, useEffect } from 'react';
import { CartContext } from '../components/providers/cart.tsx';

export default function PaymentPage() {
    const { currentEventId } = useContext(CartContext);

    const { loading, data, error } = useQuery(PaymentPageEventDocument, {
        variables: {
            eventId: currentEventId!,
        },
        skip: !currentEventId,
    });

    useEffect(() => {
        if (!currentEventId) {
            window.location.href = EVENTS_URL;
        }
    }, [currentEventId]);

    if (!loading && error) {
        return <>Error Loading Data</>;
    }

    const organizerThemeColor = data?.event?.organizerColor || '#212B36';

    const organizerPrimaryColor =
        data?.event?.organizerType === 'poweredby' ? organizerThemeColor : '#4A7754';

    return (
        <ThemeProvider>
            <>
                {loading ? (
                    <PaymentLoading />
                ) : (
                    <PaymentLoaded
                        event={data!.event}
                        organizerPrimaryColor={organizerPrimaryColor}
                    />
                )}
            </>
        </ThemeProvider>
    );
}
