import styled from '@emotion/styled';

export const NoMatchStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 80vh;
    h1 {
        color: red;
        font-size: 8rem;
        margin: 0;
        font-family: ${(props) => props.theme.typography.fontFamily};
    }
    h2 {
        color: #000;
        margin: 0;
        font-family: ${(props) => props.theme.typography.fontFamily};
    }
`;
