export interface TicketCatHelper {
    quantityIssued: number;
    quantityLimit: number;
    name: string;
}

export interface ReturnTicketCatHelper {
    totalQuantity: number;
    totalIssued: number;
}

export const ticketCounterIssued = (tickets: TicketCatHelper[]): ReturnTicketCatHelper => {
    let totalIssued = 0;
    let totalQuantity = 0;

    if (!tickets) {
        return {
            totalIssued,
            totalQuantity,
        };
    }

    for (const ticket of tickets) {
        totalIssued += ticket.quantityIssued;
        totalQuantity += ticket.quantityLimit;
    }

    return {
        totalQuantity,
        totalIssued,
    };
};
