import { NoMatchStyled } from '../components/common/styled/NoMatch.styled';

export default function NoMatch() {
    return (
        <NoMatchStyled>
            <h1>404</h1>
            <h2>Page Not Found</h2>
        </NoMatchStyled>
    );
}
