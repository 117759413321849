import { ConfirmationPageEventQuery } from '../../graphql/graphql';
import { dateFormatter, imageFormatter, timeFormatter } from '../../utils/formatter';

import { Box, Stack } from '@mui/joy';
import { StyledEventTitle,StyledTextSecondary } from '../common/styled/StaticElementsJoy.styled';

interface OrderInfoJoyProps {
    event: ConfirmationPageEventQuery['event'];
    organizerPrimaryColor: string;
}

export default function OrderInfoJoy({ event,organizerPrimaryColor }: OrderInfoJoyProps) {
    return (
            <Box>
            {event && (
                <>
                    <Box
                        component="picture"
                        sx={{
                            width: '100%',
                            display: 'inline-block',
                            marginBottom: 3,
                            img: {
                                borderRadius: 4,
                            },
                        }}
                    >
                        {/* Whichever source matches the media query will be used */}
                        <source
                            srcSet={`${imageFormatter(
                                event.eventImage
                            )}?w=1150&h=400&fit=fill&fill=blur`}
                            media="(min-width: 1150px)"
                        />
                        <source
                            srcSet={`${imageFormatter(
                                event.eventImage
                            )}?w=800&h=400&fit=fill&fill=blur`}
                            media="(min-width: 800px)"
                        />
                        <Box
                            component="img"
                            src={`${imageFormatter(
                                event.eventImage
                            )}?w=500&h=300&fit=fill&fill=blur`}
                            sx={{ width: '100%' }}
                        />
                    </Box>

                    <Box sx={{ marginBottom: 1 }}>
                        <StyledEventTitle level={'body-md'}>{event.title}</StyledEventTitle>
                    </Box>

                    <Box sx={{ marginBottom: 1 }}>
                        <StyledTextSecondary level={'body-sm'}>
                            {dateFormatter(
                                new Date(event.startDate),
                                new Date(event.endDate),
                                event.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
                            )}
                        </StyledTextSecondary>
                        <StyledTextSecondary level={'body-sm'}>
                            {timeFormatter(
                                new Date(event.startDate),
                                new Date(event.endDate),
                                event.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
                            )}
                        </StyledTextSecondary>
                    </Box>

                    <Box sx={{ marginBottom: 1 }}>
                        {event.place === 'address' ? (
                            <>
                                {event.address?.businessName ? (
                                    <Stack>
                                        <StyledTextSecondary level={'body-sm'}>
                                            {event.address.businessName}
                                        </StyledTextSecondary>
                                        <StyledTextSecondary level={'body-sm'}>
                                            {event.address?.street}, {event.address?.city}{' '}
                                            {event.address?.region}
                                        </StyledTextSecondary>
                                    </Stack>
                                ) : (
                                    <StyledTextSecondary level={'body-sm'}>
                                        {event.address?.formattedAddress}
                                    </StyledTextSecondary>
                                )}
                            </>
                        ) : (
                            <StyledTextSecondary level={'body-sm'}>
                                Online Event
                            </StyledTextSecondary>
                        )}
                    </Box>

                    {event.presentedBy && (
                        <Box sx={{ marginBottom: 2 }}>
                            <StyledTextSecondary level={'body-sm'}>
                                Organized by{' '}
                                <StyledTextSecondary
                                    sx={{
                                        color: organizerPrimaryColor,
                                        fontWeight: 400,
                                    }}
                                >
                                    {event.presentedByName}
                                </StyledTextSecondary>
                            </StyledTextSecondary>
                        </Box>
                    )}
                </>
            )}
            </Box>
  
    );
}
