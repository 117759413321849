export const EVENTS_URL: string = import.meta.env.VITE_ALL_EVENTS_PAGE;

export const NUVEI_MERCHANT_ID: number = import.meta.env.VITE_NUVEI_MERCHANT_ID;

export const NUVEI_SITE_ID: number = import.meta.env.VITE_NUVEI_SITE_ID;

export const NUVEI_ENV: string = import.meta.env.VITE_NUVEI_ENV;

export const RECAPTCHA_KEY: string = import.meta.env.VITE_GOOGLE_RECAPTCHA_KEY;

export const APP_URL: string = import.meta.env.VITE_APP_URL;
