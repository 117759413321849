import { StyledFooter } from './Footer.styled.tsx';

import Logo from '/images/admitone.svg';

const Footer = () => {
    return (
        <StyledFooter>
            <div className="fc_inner_container">
                <div className="fc_section">
                    <a className="fc_logo">
                        <img
                            src={Logo}
                            alt="Powered by AdmitONE"
                            style={{ width: '100%', maxWidth: '150px' }}
                        />
                    </a>
                </div>
                <ul className="fc_ul">
                    <li>
                        <a
                            className="fc_ul_link"
                            href={`https://community.admitone.com/privacy-policy`}
                            target="_blank"
                        >
                            Privacy Policy
                        </a>
                    </li>
                    <li>
                        <a
                            className="fc_ul_link"
                            href={`https://community.admitone.com/terms-of-service`}
                            target="_blank"
                        >
                            Terms of Services
                        </a>
                    </li>
                    <li>
                        <a
                            className="fc_ul_link"
                            href={`mailto:community@admitone.com`}
                            target="_blank"
                        >
                            Contact us
                        </a>
                    </li>
                </ul>
            </div>
        </StyledFooter>
    );
};

export default Footer;
