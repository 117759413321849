import { ButtonBaseOwnProps } from '@mui/material';
import { darken, lighten } from 'polished';
import { ClassAttributes } from 'react';

import { Button, IconButton, styled } from '@mui/joy';

interface StyledButtonProps extends ButtonBaseOwnProps {
    backgroundcolor?: string;
    target?: string;
}

interface StyleActionButtonProps extends ClassAttributes<HTMLButtonElement> {
    setcolor: string;
}

export const StyledATag = styled('a')<{ setcolor?: string }>`
    color: ${(props) => props.setcolor};
    text-decoration: none;
    display: inline-block;
    transition: all 0.3s;
    padding: 8px 11px;
    border-radius: 4px;
    &:hover {
        background-color: ${(props) => `${props.setcolor}12`};
    }
    p {
        font-size: 14px;
    }
`;

export const StyledActionButton = styled(Button)<StyleActionButtonProps>`
    color: ${(props) => props.setcolor};
    font-size: 14px;
    &:hover {
        background-color: ${(props) => `${props.setcolor}12`};
    }
`;

export const StyledButton = styled(Button)<StyledButtonProps>`
    color: #fff;
    background-color: ${(props) => (props.disabled ? 'grey' : props.backgroundcolor)};
    text-transform: none;
    &:hover {
        background-color: ${(props) =>
            props.disabled ? 'grey' : darken(0.15, props.backgroundcolor as string)};
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    }
`;

export const StyledButtonLight = styled(Button)<StyledButtonProps>`
    text-transform: none;
    background-color: ${(props) => lighten(0.5, props.backgroundcolor as string)};
    color: ${(props) => props.backgroundcolor};
    &:hover {
        background-color: ${(props) => lighten(0.4, props.backgroundcolor as string)};
    }
`;

export const StyledBorderButton = styled(Button)<StyledButtonProps>`
    color: ${(props) =>
        props.disabled ? props.theme.palette.neutral.solidDisabledColor : props.backgroundcolor};
    border: ${(props) => (props.disabled ? 'none' : `1px solid ${props.backgroundcolor}`)};
    background-color: ${(props) =>
        props.disabled ? props.theme.palette.neutral.softDisabledColor : '#fff'};
    text-transform: none;
    &:hover {
        background-color: ${(props) => `${props.backgroundcolor}20`};
    }
`;

export const StyledBackToTopBtn = styled(Button)<StyledButtonProps>`
    position: fixed;
    bottom: 60px;
    right: 5%;
    z-index: 10000;
    font-size: 14px;
    text-transform: capitalize;
    background-color: ${(props) => lighten(0.5, props.backgroundcolor as string)};
    color: ${(props) => props.backgroundcolor};
    &:hover {
        background-color: ${(props) => lighten(0.35, props.backgroundcolor as string)};
    }
    @media (max-width: 1200px) {
        right: 24px;
    }
    @media (max-width: 600px) {
        bottom: 45px;
    }
`;

export const StyledIconButtonUnpadded = styled(IconButton)<StyledButtonProps>`
    color: #fff;
    background-color: ${(props) =>
        props.disabled ? props.theme.palette.neutral.softDisabledColor : props.backgroundcolor};
    &:hover {
        background-color: ${(props) =>
            props.disabled
                ? props.theme.palette.neutral.softDisabledColor
                : darken(0.15, props.backgroundcolor as string)};
        color: #fff;
    }
`;
