import { Box,BoxProps, styled } from '@mui/joy';

interface CircleStepProps extends BoxProps {
    step: number;
    backgroundcolor: string;
    currentstep: number;
}


export const StepBoxContainer = styled(Box)`
    @media (max-width: 900px) {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        p {
            margin-top: 5px;
        }
    }
    display: flex;
    align-items: center;
    margin: 0 8px;
`;

export const CircleStep = styled(Box)<CircleStepProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    font-size: 12px;
    color: #fff;
    height: 24px;
    width: 24px;
    margin-right: 8px;
    background-color: ${(props) =>
        props.currentstep >= props.step ? props.backgroundcolor : 'rgba(0, 0, 0, 0.38)'};
`;

export const ErrorAlert = styled(Box)`
    background-color: #d32f2f;
    padding: 15px 16px;
    display: flex;
    color: #fff;
    margin-bottom: 40px;
    justify-content: space-between;
    p {
        line-height: 20px;
    }
`;
