import { useMemo, useCallback, useContext } from 'react';

import {
    AddonCategoryFragment,
    AddonVariationObject,
    OrderSummaryEventQuery,
} from '../../graphql/graphql';
import { LineItemType } from '../../utils/calculations.ts';
import { IncreaseDecreaseContainer, AddonCategoryBox } from './orderSummaryPageJoy.styled';

// MUI
import { Typography, Box, Stack, Chip } from '@mui/joy';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import ProductDescription from './ticketCategoryDescriptionJoy.component';
import { StarsRounded } from '@mui/icons-material';
import { addonIdsToKey } from '../../utils/addons.ts';
import { AddonVariationCardJoy } from './addonVariationCardJoy.component.tsx';
import { CartContext } from '../providers/cart.tsx';
import { FeeID } from '../../utils/calculations.ts';
import { formatPrice } from '../../utils/formatter.ts';

interface AddonCategoryCardProps {
    event: OrderSummaryEventQuery['event'];
    eventId: string;
    addonCategory: AddonCategoryFragment;
    organizerPrimaryColor: string;
    unlockCodesUsed: readonly string[];
}

type AddonVariationPartial = Omit<AddonVariationObject, 'enteredAmountDelta' | 'order'>;

export default function AddonCategoryCardJoy({
    event,
    eventId,
    addonCategory,
    organizerPrimaryColor,
    unlockCodesUsed,
}: AddonCategoryCardProps) {
    const { cart, setAddonQuantity, getCalculations } = useContext(CartContext);

    const { lineItems } = useMemo(() => getCalculations(event), [getCalculations, event]);

    const hasTickets = useMemo(
        () => lineItems.filter((item) => item.type === LineItemType.TICKET_LINE_ITEM).length > 0,
        [lineItems]
    );

    const inventoryMatrix = useMemo(() => {
        const acc: { [variationId: string]: number } = {};

        if (!Array.isArray(addonCategory?.variations)) {
            return acc;
        }

        return addonCategory.variations.reduce((a, v) => {
            a[v.variationId] =
                v.quantityLimit -
                (v.quantityDistributed.distributed + v.quantityDistributed.inCart);
            return a;
        }, acc);
    }, [addonCategory?.variations]);

    const totalInventoryCount = useMemo(() => {
        return Object.values(inventoryMatrix).reduce((a, b) => a + b);
    }, [inventoryMatrix]);

    const totalAddonsInCart = useMemo(() => {
        const { addons } = cart;

        let count = 0;

        for (const key in addons) {
            if (key in addons) {
                count += addons[key];
            }
        }

        return count;
    }, [cart]);

    const minAddonQuantity = addonCategory?.quantityMin ?? 1;

    const incrementQuantity = useCallback(
        (variationId: string) => {
            if (!variationId) {
                return;
            }

            const key = addonIdsToKey(addonCategory.categoryId, variationId);
            const currentQuantity = cart?.addons?.[key] ?? 0;
            const inventory = inventoryMatrix[variationId] ?? 500_000;
            const variation = addonCategory?.variations?.find((v) => v.variationId === variationId);

            let minAdd = currentQuantity >= minAddonQuantity ? 1 : minAddonQuantity;

            if (!variation) {
                return;
            }

            if (currentQuantity + minAddonQuantity > variation.quantityLimit) {
                return;
            }

            if (totalAddonsInCart + minAddonQuantity > 20) {
                if (addonCategory.isQuantityMinStrict) {
                    return;
                }
                minAdd = 1;
            }

            if (currentQuantity + minAddonQuantity > inventory) {
                return;
            }

            setAddonQuantity(addonCategory.categoryId, variationId, currentQuantity + minAdd);
        },
        [cart, addonCategory, totalInventoryCount, minAddonQuantity, setAddonQuantity]
    );

    const decrementQuantity = useCallback(
        (variationId: string) => {
            if (!variationId) {
                return;
            }

            const key = addonIdsToKey(addonCategory.categoryId, variationId);
            const currentQuantity = cart?.addons?.[key] ?? 0;
            const variation = addonCategory?.variations?.find((v) => v.variationId === variationId);

            if (!variation) {
                return;
            }

            if (currentQuantity - minAddonQuantity < 0) {
                if (totalInventoryCount === 0) {
                    return;
                }

                setAddonQuantity(addonCategory.categoryId, variationId, 0);

                return;
            }

            if (totalInventoryCount - minAddonQuantity < minAddonQuantity) {
                return;
            }

            if (currentQuantity - 1 < minAddonQuantity) {
                setAddonQuantity(addonCategory.categoryId, variationId, 0);
                return;
            }

            setAddonQuantity(addonCategory.categoryId, variationId, currentQuantity - 1);
        },
        [cart, addonCategory, totalInventoryCount, minAddonQuantity, setAddonQuantity]
    );

    const totalAddonsRemaining = useMemo(() => {
        return Object.values(inventoryMatrix).reduce((a, b) => a + b);
    }, [inventoryMatrix]);

    const isAddonSoldOut = totalAddonsRemaining <= 0;

    const ImageBox = useMemo(() => {
        if (addonCategory?.imageKey) {
            return (
                <img
                    src={`https://media.admitone.com/addons/${addonCategory.imageKey}?width=64&height=64&fit=fill&fill=blur`}
                    alt={addonCategory.name}
                    style={{
                        borderRadius: 8,
                    }}
                />
            );
        } else {
            return (
                <Box
                    sx={{
                        width: 64,
                        height: 64,
                        background: organizerPrimaryColor,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 8,
                    }}
                >
                    <StarsRounded sx={{ width: 60, height: 60, color: 'white' }} />
                </Box>
            );
        }
    }, [addonCategory, organizerPrimaryColor]);

    const VariationBoxes = useMemo(() => {
        if (!Array.isArray(addonCategory?.variations) || addonCategory?.variations.length <= 1) {
            return null;
        }

        return addonCategory.variations.map((variation) => {
            const key = addonIdsToKey(addonCategory.categoryId, variation.variationId);
            const quantity = cart?.addons?.[key] ?? 0;

            const variationMin = addonCategory.quantityMin ?? 1;

            return (
                <AddonVariationCardJoy
                    key={variation.variationId}
                    variation={variation}
                    organizerPrimaryColor={organizerPrimaryColor}
                    quantity={quantity}
                    disableAdd={totalAddonsInCart + variationMin > 20}
                    disableRemove={false}
                    incrementQuantity={() => incrementQuantity(variation.variationId)}
                    decrementQuantity={() => decrementQuantity(variation.variationId)}
                    disableAction={!hasTickets}
                    totalInCart={totalAddonsInCart}
                />
            );
        });
    }, [addonCategory?.variations, incrementQuantity, decrementQuantity]);

    const displayedPrice = useMemo(() => {
        if (addonCategory?.variations?.length === 1) {
            const basePrice = addonCategory.variations[0].unitPrice.find(
                (u) => u.id == FeeID.BASE_PRICE
            );

            if (!basePrice) {
                return '';
            }

            return basePrice.charge.amount === 0
                ? 'Free'
                : formatPrice(basePrice.charge.amount, basePrice.charge.currency);
        } else {
            let lowestPrice: number | null = null;
            let highestPrice: number | null = null;
            let currency = 'CAD';

            addonCategory.variations?.forEach((variation) => {
                variation.unitPrice.forEach((u) => {
                    if (u.id !== FeeID.BASE_PRICE) {
                        return;
                    }

                    if (lowestPrice === null || u.charge.amount < lowestPrice) {
                        lowestPrice = u.charge.amount;
                    }

                    if (highestPrice === null || u.charge.amount > highestPrice) {
                        highestPrice = u.charge.amount;
                    }

                    if (u.charge.currency !== currency) {
                        currency = u.charge.currency;
                    }
                });
            });

            const lowestPriceString = lowestPrice ? formatPrice(lowestPrice, currency) : 'Free';
            const highestPriceString = highestPrice ? formatPrice(highestPrice, currency) : 'Free';

            if (lowestPriceString === highestPriceString) {
                return highestPriceString;
            } else {
                return lowestPriceString + ' - ' + highestPriceString;
            }
        }
    }, [addonCategory?.variations]);

    return (
        <AddonCategoryBox sx={{ paddingBottom: 0.5 }}>
            <Box>
                {/* TODO: Enabled Addon Tags */}
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                        }}
                    >
                        {ImageBox}
                        <Stack sx={{ ml: 2, gap: 0.5, mb: 0.75 }}>
                            <Typography>
                                {addonCategory.name}{' '}
                                {unlockCodesUsed?.length > 0
                                    ? ` - ${unlockCodesUsed.join(', ')}`
                                    : null}
                            </Typography>
                            {unlockCodesUsed?.length > 0 && (
                                <Typography
                                    sx={{
                                        color: 'rgba(0,0,0,0.6)',
                                        fontSize: '12px',
                                        mt: '10px',
                                        display: 'flex',
                                    }}
                                >
                                    <LockOpenIcon fontSize={'inherit'} sx={{ mr: '5px' }} />{' '}
                                    <span>Unlocked Add-on</span>
                                </Typography>
                            )}
                            <Typography sx={{ fontWeight: 600 }}>{displayedPrice}</Typography>
                            <ProductDescription
                                description={addonCategory.description as string}
                                organizerPrimaryColor={organizerPrimaryColor}
                            />
                        </Stack>

                        {isAddonSoldOut ? (
                            <Chip sx={{ ml: 'auto', mr: 1 }}>Sold Out</Chip>
                        ) : (
                            <Stack sx={{ ml: 'auto', mr: 1 }}>
                                {addonCategory.variations?.length === 1 &&
                                    VariationBoxes === null && (
                                        <DefaultVariationQuantitySelectors
                                            incrementQuantity={incrementQuantity}
                                            decrementQuantity={decrementQuantity}
                                            disableAction={!hasTickets}
                                            eventId={eventId}
                                            addonCategory={addonCategory}
                                            variation={addonCategory.variations[0]}
                                            organizerPrimaryColor={organizerPrimaryColor}
                                            inventory={
                                                inventoryMatrix[
                                                    addonCategory.variations[0].variationId
                                                ] ?? 500_000
                                            }
                                            isAddonSoldOut={isAddonSoldOut}
                                        />
                                    )}
                                {totalAddonsInCart === 20 && (
                                    <Typography
                                        sx={{ color: 'red', alignSelf: 'center', fontSize: 12 }}
                                    >
                                        Max 20 per order
                                    </Typography>
                                )}
                            </Stack>
                        )}
                    </Box>
                </Box>
            </Box>
            {VariationBoxes}
        </AddonCategoryBox>
    );
}

interface DefaultVariationQuantitySelectorsProps {
    eventId: string;
    addonCategory: AddonCategoryFragment;
    variation: AddonVariationPartial;
    incrementQuantity: (id: string) => void;
    decrementQuantity: (id: string) => void;
    organizerPrimaryColor: string;
    inventory: number;
    isAddonSoldOut: boolean;
    disableAction?: boolean;
}

function DefaultVariationQuantitySelectors({
    addonCategory,
    variation,
    incrementQuantity,
    decrementQuantity,
    organizerPrimaryColor,
    inventory,
    isAddonSoldOut,
    disableAction,
}: DefaultVariationQuantitySelectorsProps) {
    const { cart } = useContext(CartContext);

    const variationInCart = useMemo(() => {
        if (!addonCategory || !variation) {
            return 0;
        }

        const key = addonIdsToKey(addonCategory.categoryId, variation.variationId);
        return cart.addons?.[key] ?? 0;
    }, [addonCategory.categoryId, variation.variationId, cart]);

    const maximum = useMemo(() => {
        if (!variation) {
            return 0;
        }

        if (typeof addonCategory.quantityMax === 'number') {
            return Math.min(inventory, addonCategory.quantityMax, variation.quantityLimit);
        } else {
            return Math.min(inventory, variation.quantityLimit);
        }
    }, [inventory, variation.quantityLimit, addonCategory.quantityMax]);

    const totalAddonsInCart = useMemo(() => {
        const { addons } = cart;

        let count = 0;

        for (const key in addons) {
            if (key in addons) {
                count += addons[key];
            }
        }

        return count;
    }, [cart]);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                alignSelf: 'flex-start',
                marginBottom: '8px',
                gap: 3,
            }}
        >
            <IncreaseDecreaseContainer
                id={`remove_ticket_${variation.variationId}`}
                disabled={variationInCart === 0 || disableAction}
                onClick={() => decrementQuantity(variation.variationId)}
                setcolor={organizerPrimaryColor}
            >
                <RemoveIcon className="plus_minus_icon" />
            </IncreaseDecreaseContainer>
            <Typography level="body-md" sx={{ fontWeight: 600 }}>
                {variationInCart}
            </Typography>
            <IncreaseDecreaseContainer
                id={`add_ticket_${variation.variationId}`}
                setcolor={organizerPrimaryColor}
                disabled={
                    variationInCart === maximum ||
                    isAddonSoldOut ||
                    disableAction ||
                    totalAddonsInCart === 20
                }
                onClick={() => incrementQuantity(variation.variationId)}
            >
                <AddIcon className="plus_minus_icon" />
            </IncreaseDecreaseContainer>
        </Box>
    );
}
