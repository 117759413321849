import { extendTheme, CssVarsProvider, Sheet, CssBaseline } from '@mui/joy';
import type { PaletteRange } from '@mui/joy/styles';
import {
    Experimental_CssVarsProvider as MaterialCssVarsProvider,
    experimental_extendTheme as extendMaterialTheme,
    THEME_ID,
} from '@mui/material/styles';

interface ThemeProviderProps {
    children: React.ReactNode;
}
declare module '@mui/joy/styles' {
    interface ColorPalettePropOverrides {
        // apply to all Joy UI components that support `color` prop
        admitOnePro: true;
    }
    interface Palette {
        // this will make the node `secondary` configurable in `extendTheme`
        // and add `secondary` to the theme's palette.
        admitOnePro: PaletteRange;
    }
}

export function ThemeProvider({ children }: ThemeProviderProps) {
    const joyTheme = extendTheme({
        colorSchemes: {
            light: {
                palette: {
                    primary: {
                        50: '#F6FFFA',
                        100: '#EBF8F1',
                        200: '#E0F5EB',
                        300: '#C8FACD',
                        400: '#5BE584',
                        500: '#4A7754',
                        600: '#007B55',
                        700: '#005249',
                        800: '#002723',
                        900: '#00201D',
                        outlinedBorder: 'var(--joy-palette-primary-500)',
                    },
                    admitOnePro: {
                        600: '#212B36',
                    },
                },
            },
        },
        breakpoints: {
            // @ts-ignore
            values: {
                xs: 0,
                sm: 500,
                md: 900,
                lg: 1200,
                xl: 1800,
            },
        },
        components: {
            JoySelect: {
                styleOverrides: {
                    // @ts-ignore
                    root: ({ theme }) => ({
                        backgroundColor: theme.palette.common.white,
                        'button:focus': {
                            outline: 'none',
                        },
                    }),
                },
            },
            JoyInput: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        backgroundColor: theme.palette.common.white,
                        '&:focus': {
                            outline: 'none',
                        },
                    }),
                },
            },
            JoyTextarea: {
                styleOverrides: {
                    // @ts-ignore
                    root: ({ theme }) => ({
                        backgroundColor: theme.palette.common.white,
                        '&:focus': {
                            outline: 'none',
                        },
                    }),
                },
            },
            JoyRadio: {
                styleOverrides: {
                    // @ts-ignore
                    root: ({ theme }) => ({
                        borderColor: theme.palette.primary[500],
                    }),
                },
            },
            JoyMenuItem: {
                styleOverrides: {
                    root: {
                        '& a': { textDecoration: 'none' },
                    },
                },
            },
            JoyDrawer: {
                styleOverrides: {
                    // @ts-ignore
                    root: ({ theme }) => ({
                        '& .MuiDrawer-content': {
                            backgroundColor: theme.palette.common.white,
                            width: '100%',
                            maxWidth: '576px',
                            padding: 32,
                            '@media (max-width: 1200px)': {
                                padding: 24,
                            },
                        },
                    }),
                },
            },
        },
        fontFamily: {
            display: 'Inter, sans-serif',
            body: 'Inter, sans-serif',
        },
        typography: {
            'title-md': {
                fontWeight: 600,
            },
            'title-sm': {
                fontWeight: 600,
            },
            'body-xs': {
                fontWeight: 600,
            },
        },
    });

    const materialTheme = extendMaterialTheme({
        colorSchemes: {
            light: {
                // @ts-ignore
                palette: {
                    primary: {
                        light: '#4A7754',
                        main: '#4A7754',
                        dark: '#002723',
                        contrastText: '#FFFFFF',
                    },
                    error: {
                        main: '#C41C1C',
                    },
                },
            },
        },
        typography: {
            fontFamily: 'Inter, sans-serif',
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        borderColor: theme.palette.primary.main,
                        '&:focus': {
                            outlineColor: theme.palette.primary.main,
                        },
                        '&:hover': {
                            borderColor: theme.palette.primary.main,
                        },
                    }),
                },
            },
            MuiTouchRipple: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        outlineColor: theme.palette.primary.main,
                    }),
                },
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: () => ({
                        color: '#C41C1C',
                        fontSize: 12,
                    }),
                },
            },
        },
    });

    return (
        <MaterialCssVarsProvider theme={{ [THEME_ID]: materialTheme }}>
            <CssVarsProvider theme={joyTheme} defaultMode="light">
                <CssBaseline />
                <Sheet
                    sx={{
                        width: '100%',
                        minHeight: '100svh',
                        backgroundColor: (theme) => theme.palette.common.white,
                    }}
                >
                    {children}
                </Sheet>
            </CssVarsProvider>
        </MaterialCssVarsProvider>
    );
}
