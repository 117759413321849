import { Box, Chip, ChipProps, Stack, ButtonProps, IconButton, styled } from '@mui/joy';

import { darken, lighten } from 'polished';

interface IncreaseDecreaseProps extends ButtonProps {
    setcolor: string;
}

interface CustomTicketChipProps extends ChipProps {
    setcolor: string;
}

export const AddonCategoryBox = styled(Stack)`
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 15px;
    border: 1px solid rgba(221, 231, 238, 1);
`;

export const TicketCategoryBox = styled(Stack)`
    border-radius: 8px;
    margin-bottom: 15px;
    border: 1px solid rgba(221, 231, 238, 1);
`;

export const CheckoutStack = styled(Stack)`
    padding: 32px;
`;

export const MoneyBox = styled(Box)`
    display: flex;
    justify-content: space-between;
    line-height: 150%;
    p {
        font-size: 16px;
    }
`;

export const IncreaseDecreaseContainer = styled(IconButton)<IncreaseDecreaseProps>`
    color: ${(props) => (props.disabled ? 'rgba(159, 166, 173, 1)' : 'white')};
    background-color: ${(props) => (props.disabled ? 'rgba(240, 244, 248, 1)' : props.setcolor)};
    &:hover {
        color: ${(props) => (props.disabled ? 'rgba(159, 166, 173, 1)' : 'white')};
        background-color: ${(props) =>
            props.disabled ? 'rgba(240, 244, 248, 1)' : darken(0.15, props.setcolor as string)};
    }
    .plus_minus_icon: {
        color: ${(props) => (props.disabled ? 'rgba(159, 166, 173, 1)' : props.setcolor)};
        font-size: 26px;
    }
`;

export const CustomTicketChip = styled(Chip)<CustomTicketChipProps>`
    left: -1.1px;
    top: -1.1px;
    width: fit-content;
    padding: 6px;
    color: white;
    background-color: ${(props) => lighten(0.2, props.setcolor)};
    border-radius: 8px 0 8px 0;
    font-weight: 600;
`;
