import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const StyledHR = styled.hr`
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin: 1em 0;
    padding: 0;
    width: 100%;
`;

export const StyledEventTitle = styled(Typography)`
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.25px;
`;

export const SROnlyLabel = styled.label`
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
`;
