// Mui
import { Box, Typography, Stack } from '@mui/joy';
import { StyledEventTitle,StyledTextPrimary } from '../common/styled/StaticElementsJoy.styled';

// Shared
import { dateFormatter,timeFormatter,imageFormatterWide } from '../../utils/formatter';

// Types
import { PaymentPageEventQuery } from '../../graphql/graphql';

import { margin3 } from '../../utils/sharedStyleVariables';

interface PaymentLoadedProps {
    event: PaymentPageEventQuery['event'];
    organizerPrimaryColor: string;
}

export default function PaymentLoadedEventInfo({
    event,
    organizerPrimaryColor,
}: PaymentLoadedProps) {
    return (
        <Box
            sx={{
                display: 'flex',
                gridGap: {md:69,sm:24,xs:24},
                width: '100%',
                flexDirection: { md: 'row', sm: 'column',xs: 'column' },
            }}
        >
            <Box
                sx={{
                    mb: margin3,
                    maxWidth: { md: '323px', sm: '500px', xs: '100%' },
                    width: '100%',
                    margin: '0px',
                }}
            >
                <Box
                    component="img"
                    src={`${imageFormatterWide(event?.eventImage)}?w=323&h=214&fit=fill&fill=blur`}
                    sx={{ width: '100%', borderRadius: '4px' }}
                />
            </Box>

            <Box sx={{ width: { md: 'calc(100% - 323px)', sm:'100%', xs: '100%' } }}>
                <StyledEventTitle level={'body-lg'} sx={{marginBottom:2}}>{event?.title}</StyledEventTitle>

                <StyledTextPrimary level={'body-sm'} sx={{ fontWeight: 600 }}>
                    {dateFormatter(
                        new Date(event?.startDate),
                        new Date(event?.endDate),
                        event?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
                    )}
                </StyledTextPrimary>
                <StyledTextPrimary level={'body-sm'} sx={{ fontWeight: 600 }}>
                    {timeFormatter(
                        new Date(event?.startDate),
                        new Date(event?.endDate),
                        event?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
                    )}
                </StyledTextPrimary>

                <Stack sx={{ mb: margin3,mt:2 }}>
                    {event?.place === 'address' ? (
                        <>
                            {event?.address?.businessName ? (
                                <Stack>
                                    <StyledTextPrimary level={'body-sm'} sx={{ fontWeight: 600 }}>
                                        {event?.address.businessName}
                                    </StyledTextPrimary>

                                    <StyledTextPrimary level={'body-sm'} sx={{ fontWeight: 600 }}>
                                        {event?.address?.street}, {event?.address?.city}{' '}
                                        {event?.address?.region}
                                    </StyledTextPrimary>
                                </Stack>
                            ) : (
                                <StyledTextPrimary level={'body-sm'} sx={{ fontWeight: 600 }}>
                                    {event?.address?.formattedAddress}
                                </StyledTextPrimary>
                            )}
                        </>
                    ) : (
                        <StyledTextPrimary level={'body-sm'} sx={{ fontWeight: 600 }}>
                            Online Event
                        </StyledTextPrimary>
                    )}
                </Stack>
                {event?.presentedBy && event?.presentedByName && (
                    <StyledTextPrimary level={'body-sm'} sx={{ fontWeight: 600 }}>
                        Organized by{' '}
                        <Typography
                            sx={{
                                color: organizerPrimaryColor,
                                fontWeight: 400,
                            }}
                        >
                            {event?.presentedByName}
                        </Typography>
                    </StyledTextPrimary>
                )}
            </Box>
        </Box>
    );
}
