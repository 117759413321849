import { useContext, useState } from 'react';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';

//Variables / Types
import { EVENTS_URL } from '../config';
import { useQuery } from '@apollo/client';
import { OrderSummaryEventDocument, OrderSummaryEventQuery } from '../graphql/graphql';
import { CustomOrderSummaryPageProps } from '../types/common';

// MUI / Components
import { ThemeProvider } from '../ThemeProvider';
import { Container } from '@mui/material';
import HeaderJoyComponent from '../components/common/HeaderJoy.component';
import OrderSummaryLoading from '../components/orderSummary/orderSummaryLoading.component';
import OrderSummaryLoadedJoy from '../components/orderSummary/orderSummaryLoadedJoy.component';
import { CartContext } from '../components/providers/cart.tsx';

export default function OrderSummaryPage({ match }: CustomOrderSummaryPageProps) {
    const { addUnlockCode, addReferralCode, sanitizeCart } = useContext(CartContext);

    const [errorAlert, setErrorAlert] = useState<string | null>(null);

    const history = useHistory();
    const location = useLocation();

    const { loading, data, error } = useQuery<OrderSummaryEventQuery>(OrderSummaryEventDocument, {
        variables: {
            eventId: match.params.id,
        },
        fetchPolicy: 'network-only',
        onCompleted: async (data) => {
            if (!data?.event) {
                return;
            }

            const query = new URLSearchParams(location.search);

            if (data.event.fbpid) {
                ReactPixel.init(data.event.fbpid);
                ReactPixel.pageView();
            }

            const reset = query.get('reset');
            sanitizeCart(data.event, Boolean(reset));

            const referral = query.get('referral');

            console.log(referral);

            if (referral && referral?.length > 0) {
                addReferralCode(referral);
            }

            const unlockCodes = query.get('unlockcodes');

            if (unlockCodes) {
                const unlockCodesArray = unlockCodes
                    .split(',')
                    .map((c) => c.toUpperCase().trim())
                    .filter(String);

                if (unlockCodesArray?.length > 0) {
                    addUnlockCode(...unlockCodesArray);
                }
            }

            query.delete('reset');
            query.delete('referral');
            query.delete('unlockcodes');

            history.replace({
                ...location,
                search: query.toString() === '' ? '' : `?${query.toString()}`,
            });
        },
    });

    const organizerThemeColor = data?.event?.organizerColor || '#212B36';

    const organizerPrimaryColor =
        data?.event?.organizerType === 'poweredby' ? organizerThemeColor : '#4A7754';

    const organizerSecondaryColor =
        data?.event?.organizerType === 'poweredby' ? organizerThemeColor : '#01680B';

    if (!loading && !data?.event) {
        return <Redirect to="/404" />;
    }

    if (!loading && error) {
        return <>Error Loading Data</>;
    }

    return (
        <ThemeProvider>
            <>
                <Container sx={{ py: 6 }}>
                    {loading ? (
                        <>
                            <OrderSummaryLoading />
                        </>
                    ) : (
                        <>
                            <HeaderJoyComponent
                                previousPage={`${EVENTS_URL}/${data?.event?.shortEventId}`}
                                step={1}
                                organizerPrimaryColor={organizerPrimaryColor}
                                organizerSecondaryColor={organizerSecondaryColor}
                                headerTitle={'Order Summary'}
                                setErrorAlert={setErrorAlert}
                                errorAlert={errorAlert}
                            />
                            <OrderSummaryLoadedJoy
                                event={data?.event}
                                organizerPrimaryColor={organizerPrimaryColor}
                                organizerSecondaryColor={organizerSecondaryColor}
                                setErrorAlert={setErrorAlert}
                            />
                        </>
                    )}
                </Container>
            </>
        </ThemeProvider>
    );
}
