import { useState, useEffect } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { StyledBackToTopBtn } from './styled/ActionablesJoy.styled';

const BackToTopButtonJoy = ({ organizerPrimaryColor }: { organizerPrimaryColor: string }) => {
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        const scrollY = window.scrollY || document.documentElement.scrollTop;
        const halfViewportHeight = window.innerHeight / 2;
        setIsVisible(scrollY > halfViewportHeight);
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            {isVisible && (
                <StyledBackToTopBtn
                    onClick={scrollToTop}
                    startDecorator={<ArrowUpwardIcon />}
                    backgroundcolor={organizerPrimaryColor}
                >
                    Back to Top
                </StyledBackToTopBtn>
            )}
        </>
    );
};

export default BackToTopButtonJoy;
