import { MouseEventHandler, useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ics } from 'calendar-link';

// MUI
import CloseIcon from '@mui/icons-material/Close';

// GQL
import { ConfirmationPageEventQuery, DownloadTicketsDocument } from '../../graphql/graphql';

// Formater
import { EVENTS_URL, APP_URL } from '../../config';
import { useApolloClient } from '@apollo/client';

import { ThemeProvider } from '../../ThemeProvider';
import HeaderJoyComponent from '../common/HeaderJoy.component';
import { Typography, Box, Container, Stack } from '@mui/joy';
import { Snackbar, IconButton } from '@mui/material';
import {
    StyledButton,
    StyledBorderButton,
    StyledButtonLight,
    StyledActionButton,
} from '../common/styled/ActionablesJoy.styled';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Link from '@mui/joy/Link';
import EventIcon from '@mui/icons-material/Event';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import OrderInfoJoy from '../orderSummary/orderEventInfoBlockJoy.component';
import { downloadFile } from '../../utils/downloadFile.ts';
import { CartContext } from '../providers/cart.tsx';
import * as Sentry from '@sentry/react';

dayjs.extend(utc);
dayjs.extend(timezone);

export interface ConfirmationPageLoadedProps {
    event: ConfirmationPageEventQuery['event'];
    organizerPrimaryColor: string;
}

export interface CalEventObj {
    title: string;
    description: string;
    start: string;
    end: string;
    location: string;
}

export default function ConfirmationPageLoaded({
    event,
    organizerPrimaryColor,
}: ConfirmationPageLoadedProps) {
    const { processedOrderId, resetCart, setProcessedOrderId, getCalculations, currentEventId } =
        useContext(CartContext);
    const [orderIdSnapshot] = useState(processedOrderId);
    const [{ lineItems }] = useState(getCalculations(event));
    const apollo = useApolloClient();

    useEffect(() => {
        // We want to set the cart for the final time in component state/memory, then purge local storage
        if (orderIdSnapshot && lineItems?.length > 0) {
            resetCart(currentEventId as string);
            setProcessedOrderId(null);
        }
    }, []);

    const calEvent: CalEventObj = {
        title: event!.title,
        description: event!.description ?? '',
        start: dayjs.tz(event!.startDate, event!.timezone!).toString(),
        end: dayjs.tz(event!.endDate, event!.timezone!).toString(),
        location: event!.place === 'online' ? 'Online' : event!.address?.formattedAddress ?? '',
    };

    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleDownloadTickets: MouseEventHandler<HTMLButtonElement> = async () => {
        if (!orderIdSnapshot) {
            return;
        }

        try {
            const resp = await apollo.mutate({
                mutation: DownloadTicketsDocument,
                variables: {
                    orderId: orderIdSnapshot,
                },
            });

            if (!resp.data?.downloadTicketsAtConfirmation) {
                return;
            }

            downloadFile(resp.data.downloadTicketsAtConfirmation);
        } catch (err) {
            console.error(err);
            Sentry.captureException(err);
        }
    };

    const action = (
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
        </IconButton>
    );

    return (
        <ThemeProvider>
            <>
                <Container sx={{ py: '60px' }}>
                    <HeaderJoyComponent
                        previousPage={`/${event!.shortEventId}`}
                        step={3}
                        organizerPrimaryColor={organizerPrimaryColor}
                        headerTitle="Your order is confirmed!"
                    />
                    <Typography sx={{ mb: '30px', fontSize: 18 }}>
                        Click on the button below to download your tickets, a confirmation email
                        will also be sent. If you do not see your confirmation email in your inbox
                        in a few minutes, please check your "junk mail" or "spam" folder.
                    </Typography>

                    {/* Check if we need it.......................................... */}

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: {
                                md: 'row',
                                sm: 'column-reverse',
                                xs: 'column-reverse',
                            },
                            gap: 3,
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box sx={{ marginBottom: 2, width: '100%', maxWidth: 466 }}>
                            <Box
                                sx={{
                                    backgroundColor: (theme) => theme.palette.neutral[50],
                                    padding: 3,
                                    borderRadius: 8,
                                }}
                            >
                                <Typography sx={{ mb: 2, fontWeight: 600 }}>
                                    Download your ticket
                                </Typography>
                                <StyledButton
                                    id={'tickets_download'}
                                    sx={{ minWidth: 164 }}
                                    onClick={handleDownloadTickets}
                                    backgroundcolor={organizerPrimaryColor}
                                    startDecorator={<SaveAltIcon />}
                                >
                                    Download Tickets
                                </StyledButton>
                            </Box>
                            {event?.postCheckoutCta?.link && (
                                <Box
                                    sx={{
                                        mt: 2,
                                        backgroundColor: (theme) => theme.palette.neutral[50],
                                        padding: 3,
                                        borderRadius: 8,
                                    }}
                                >
                                    <Typography sx={{ mb: 2, fontWeight: 600 }}>
                                        What’s next for you?
                                    </Typography>

                                    <Link
                                        href={event.postCheckoutCta.link}
                                        target={'_blank'}
                                        sx={{ textDecoration: 'none!important' }}
                                    >
                                        <StyledBorderButton
                                            id="external_link_button"
                                            backgroundcolor={organizerPrimaryColor}
                                            sx={{ minWidth: 164 }}
                                        >
                                            {event.postCheckoutCta.text}
                                        </StyledBorderButton>
                                    </Link>
                                </Box>
                            )}

                            <Box
                                sx={{
                                    mt: 2,
                                    backgroundColor: (theme) => theme.palette.neutral[50],
                                    padding: 3,
                                    borderRadius: 8,
                                }}
                            >
                                <Typography sx={{ mb: 2, fontWeight: 600 }}>
                                    Keep the fun going
                                </Typography>
                                <Box sx={{ displa: 'flex' }}>
                                    <Link
                                        href={EVENTS_URL}
                                        sx={{ textDecoration: 'none!important' }}
                                    >
                                        <StyledButtonLight
                                            id={'browse_more_button'}
                                            backgroundcolor={organizerPrimaryColor}
                                            sx={{
                                                marginTop: 0,
                                                mr: { xs: 0, sm: '15px' },
                                                mb: { xs: '15px', sm: 0 },
                                            }}
                                        >
                                            Browse More Events
                                        </StyledButtonLight>
                                    </Link>
                                    <Link
                                        href={`${APP_URL}/secure/my-attending`}
                                        sx={{ textDecoration: 'none!important' }}
                                    >
                                        <StyledButtonLight
                                            id={'view_upcoming_button'}
                                            backgroundcolor={organizerPrimaryColor}
                                        >
                                            View Upcoming Events
                                        </StyledButtonLight>
                                    </Link>
                                </Box>
                            </Box>
                        </Box>
                        {/* ............................................................................................. */}
                        <Box>
                            <Box
                                sx={{
                                    backgroundColor: (theme) => theme.palette.neutral[50],
                                    padding: 3,
                                    borderRadius: 8,
                                }}
                            >
                                <Box>
                                    <Typography
                                        sx={{ fontSize: 24, fontWeight: 600, marginBottom: 2 }}
                                    >
                                        Your Order Information
                                    </Typography>
                                </Box>
                                <OrderInfoJoy
                                    event={event}
                                    organizerPrimaryColor={organizerPrimaryColor}
                                />
                                <Box>
                                    <Box>
                                        <Typography level={'title-sm'} sx={{ mb: 1, mt: 1 }}>
                                            Order #{orderIdSnapshot}
                                        </Typography>
                                    </Box>
                                    <Stack sx={{ mb: '30px' }}>
                                        {lineItems.map((item) => {
                                            return (
                                                <Typography level={'body-sm'} key={item.id}>
                                                    {item.quantity} x {item.name}
                                                </Typography>
                                            );
                                        })}
                                    </Stack>
                                </Box>
                                <Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            mb: { xs: '15px', md: 0 },
                                            alignItems: 'flex-start',
                                            justifyContent: 'start',
                                            flexWrap: 'wrap',
                                            flexDirection: { xs: 'column', sm: 'row' },
                                        }}
                                    >
                                        <CopyToClipboard
                                            text={`${EVENTS_URL}/${event!.shortEventId}`}
                                            onCopy={handleClick}
                                        >
                                            <StyledActionButton
                                                id="copy_url_button"
                                                startDecorator={<InsertLinkIcon />}
                                                setcolor={organizerPrimaryColor}
                                                variant="plain"
                                                sx={{
                                                    mr: { xs: 0, sm: '15px' },
                                                    alignSelf: 'flex-start',
                                                }}
                                            >
                                                Copy Event URL
                                            </StyledActionButton>
                                        </CopyToClipboard>
                                        <Snackbar
                                            open={open}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            autoHideDuration={6000}
                                            onClose={handleClose}
                                            message="Copied to Clipboard!"
                                            action={action}
                                            sx={{
                                                backgroundColor: organizerPrimaryColor,
                                                borderRadius: '4px',
                                                '.MuiPaper-elevation6': {
                                                    backgroundColor: organizerPrimaryColor,
                                                },
                                            }}
                                        />
                                        <StyledActionButton
                                            id={'add_to_calendar'}
                                            startDecorator={<EventIcon />}
                                            setcolor={organizerPrimaryColor}
                                            variant="plain"
                                            onClick={() => {
                                                const a = document.createElement('a');
                                                a.href = ics(calEvent);
                                                a.download;
                                                a.click();
                                            }}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                alignSelf: 'flex-start',
                                            }}
                                        >
                                            Add To Calendar
                                        </StyledActionButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </>
        </ThemeProvider>
    );
}
