import FingerprintJS from '@fingerprintjs/fingerprintjs';

export const createFingerPrint = async () => {
    try {
        const fp = await FingerprintJS.load();
        const { visitorId } = await fp.get();
        return visitorId;
    } catch (err) {
        console.error(err);
        throw Error('Could not create new visitor ID');
    }
};
