import styled from '@emotion/styled';
import { Box, TextField, BaseTextFieldProps } from '@mui/material';
import { HTMLAttributes } from 'react';

interface ExtendTextFieldProps extends BaseTextFieldProps {
    setcolor: string;
}

interface ExtendedCreditDivProps extends HTMLAttributes<HTMLDivElement> {
    setcolor: string;
}

export const StyledModalBox = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 700px;
    width: 100%;
    background-color: #fff;
    box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.6);
    padding: 25px;
    border-radius: 5px;
`;

export const StyledPaymentInput = styled(TextField)<ExtendTextFieldProps>`
    width: 100%;
    margin-right: 5px;
    '& label.mui-focused': {
        color: ${(props) => props.setcolor};
    }
    '& .muioutlinedinput-root': {
        '&.mui-focused fieldset': {
            bordercolor: ${(props) => props.setcolor};
        }
    }
`;

export const StyledCreditCardInput = styled.div<ExtendedCreditDivProps>`
    border-radius: 4px;
    border: 1px solid #bfbfbf;
    padding: 0.75rem;
    transition: all 0.3s;
    &:hover {
        border: 1px solid ${(props) => props.setcolor};
    }
    &:focus-with {
        border: 1px solid ${(props) => props.setcolor};
    }
    &:focus {
        border: 1px solid ${(props) => props.setcolor};
    }
`;
