import { AddonVariationFragment } from '../../graphql/graphql.ts';
import { useMemo, useState } from 'react';
import { AddonCategoryBox, IncreaseDecreaseContainer } from './orderSummaryPageJoy.styled.tsx';
import { Box, Stack, Typography } from '@mui/joy';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { FeeID } from '../../utils/calculations.ts';
import { formatPrice } from '../../utils/formatter.ts';

interface AddonVariationCardJoyProps {
    variation: AddonVariationFragment;
    quantity: number;
    organizerPrimaryColor: string;
    disableAdd: boolean;
    disableRemove: boolean;
    incrementQuantity: () => void;
    decrementQuantity: () => void;
    disableAction?: boolean;
    totalInCart: number;
}

export const AddonVariationCardJoy = ({
    variation,
    quantity,
    organizerPrimaryColor,
    disableAdd,
    disableRemove,
    incrementQuantity,
    decrementQuantity,
    disableAction,
    totalInCart,
}: AddonVariationCardJoyProps) => {
    const [showDescription, setShowDescription] = useState(false);

    const basePrice = useMemo(() => {
        const basePrice = variation.unitPrice.find((u) => u.id === FeeID.BASE_PRICE);
        return basePrice?.charge?.amount ?? 0;
    }, [variation]);

    return (
        <AddonCategoryBox>
            <Box>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                        }}
                    >
                        <Stack sx={{ ml: -1, gap: 0.5 }}>
                            <Typography>{variation.name} </Typography>
                            <Typography sx={{ fontWeight: 600 }}>
                                {basePrice === 0 ? 'Free' : formatPrice(basePrice)}
                            </Typography>

                            {variation?.description && (
                                <Box sx={{ width: { sm: '75%' } }}>
                                    {showDescription && (
                                        <Typography
                                            sx={{
                                                color: 'rgba(0,0,0,0.6)',
                                                whiteSpace: 'pre-wrap',
                                                lineBreak: 'normal',
                                                lineHeight: '143%',
                                                letterSpacing: '0.17px',
                                                marginBottom: 1,
                                            }}
                                        >
                                            {variation.description}
                                        </Typography>
                                    )}
                                    <Typography
                                        sx={{
                                            color: organizerPrimaryColor,
                                            width: '100px',
                                            cursor: 'pointer',
                                            fontSize: 'small',
                                            fontWeight: 600,
                                        }}
                                        onClick={() => setShowDescription((state) => !state)}
                                    >
                                        {showDescription ? 'See less' : 'See more'}
                                    </Typography>
                                </Box>
                            )}
                        </Stack>
                        <Stack sx={{ ml: 'auto', mr: -0.5 }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignSelf: 'flex-start',
                                    marginBottom: '8px',
                                    gap: 2,
                                }}
                            >
                                <IncreaseDecreaseContainer
                                    id={`remove_ticket_${variation.variationId}`}
                                    disabled={disableRemove || disableAction || quantity === 0}
                                    onClick={decrementQuantity}
                                    setcolor={organizerPrimaryColor}
                                >
                                    <RemoveIcon className="plus_minus_icon" />
                                </IncreaseDecreaseContainer>
                                <Typography level="body-md" sx={{ fontWeight: 600 }}>
                                    {quantity}
                                </Typography>
                                <IncreaseDecreaseContainer
                                    id={`add_ticket_${variation.variationId}`}
                                    setcolor={organizerPrimaryColor}
                                    disabled={disableAdd || disableAction || totalInCart === 20}
                                    onClick={incrementQuantity}
                                >
                                    <AddIcon className="plus_minus_icon" />
                                </IncreaseDecreaseContainer>
                            </Box>
                        </Stack>
                    </Box>
                </Box>
            </Box>
        </AddonCategoryBox>
    );
};
