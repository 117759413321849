import Dinero, { Currency } from 'dinero.js';

export const imageFormatter = (pathOrHash?: string | null) => {
    if (pathOrHash === 'default-community.jpg' || pathOrHash === undefined || pathOrHash == null) {
        return '/images/default-community.jpg';
    }

    if (pathOrHash.startsWith('http')) {
        return pathOrHash;
    }

    if (pathOrHash.includes('-')) {
        return `https://media.admitone.com/extras/${pathOrHash}?w=800&h=800&fit=cover`;
    }

    return `https://media.admitone.com/legacy/${pathOrHash}`;
};

export const imageFormatterWide = (pathOrHash?: string | null) => {
    if (pathOrHash === 'default-community.jpg' || pathOrHash === undefined || pathOrHash == null) {
        return '/images/default-community.jpg';
    }

    if (pathOrHash.startsWith('http')) {
        return pathOrHash;
    }

    if (pathOrHash.includes('-')) {
        return `https://media.admitone.com/extras/${pathOrHash}?w=323&h=214&fit=cover`;
    }

    return `https://media.admitone.com/legacy/${pathOrHash}`;
};

export const dateFormatter = (startDate: Date, endDate: Date, timezone?: string) => {
    const timeDifference = Math.abs(endDate.getTime() - startDate.getTime());

    const hoursDifference = timeDifference / (1000 * 60 * 60);

    const start = startDate.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: timezone,
    });

    const end = endDate.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: timezone,
    });

    if (hoursDifference >= 24) {
        return `${start} - ${end}`;
    } else {
        return start;
    }
};

export const timeFormatter = (startDate: Date, endDate: Date, timezone?: string) => {
    const start = startDate.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        timeZone: timezone,
    });

    const end = endDate.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        timeZone: timezone,
        timeZoneName: 'short',
    });

    return `${start} - ${end}`;
};

export const SaleEndDateFormatter = (saleDate: Date, timezone: string) => {
    const endDate = saleDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        timeZone: timezone,
        timeZoneName: 'short',
    });

    return `Sale ends on ${endDate}`;
};

// interface Fee {
//     id: string;
//     order: number;
//     name: string;
//     charge: { amount: number; currency: string };
// }

// export const calculateTotal = (categories: MatchedCategories[], currency: string) => {
//     if (!categories || categories.length === 0) {
//         return {
//             fees: [
//                 {
//                     name: 'Subtotal',
//                     id: 'BASE',
//                     order: 0,
//                     charge: { amount: 0, currency: currency },
//                 },
//             ],
//             total: 0,
//         };
//     }
//
//     const fees: Fee[] = [];
//
//     categories.forEach((ticketCategory: MatchedCategories) => {
//         if (ticketCategory?.unitPrice && ticketCategory?.unitPrice.length > 0) {
//             ticketCategory.unitPrice.forEach((fee) => {
//                 if (fee) {
//                     const existingFee = fees.find((f) => f.id === fee?.id);
//
//                     if (existingFee) {
//                         existingFee.charge.amount += fee.charge
//                             ? (fee.charge.amount as number) * ticketCategory.quantitySelected
//                             : 0;
//                     } else {
//                         fees.push({
//                             ...fee,
//                             name:
//                                 fee.id === '000'
//                                     ? 'Subtotal'
//                                     : fee.name
//                                     ? fee.name.replace(/\(.+\)/g, '').trim()
//                                     : '',
//                             charge: fee.charge
//                                 ? {
//                                       amount:
//                                           (fee.charge.amount as number) *
//                                           ticketCategory.quantitySelected,
//                                       currency: fee.charge.currency,
//                                   }
//                                 : {
//                                       amount: 0,
//                                       currency: currency,
//                                   },
//                         } as Fee);
//                     }
//                 }
//             });
//         } else {
//             const subTotal = ticketCategory.basePrice
//                 ? (ticketCategory?.basePrice.amount as number) * ticketCategory.quantitySelected
//                 : 0;
//             const serviceFee = ticketCategory.serviceFee
//                 ? (ticketCategory?.serviceFee.amount as number) * ticketCategory.quantitySelected
//                 : 0;
//             const processingFee = ticketCategory?.processingFee
//                 ? (ticketCategory?.processingFee.amount as number) * ticketCategory.quantitySelected
//                 : 0;
//
//             const preExistingSubTotal = fees.find((fee) => fee.id === 'BASE');
//
//             if (preExistingSubTotal) {
//                 preExistingSubTotal.charge.amount += subTotal;
//             } else {
//                 fees.push({
//                     id: 'BASE',
//                     order: 0,
//                     name: 'Subtotal',
//                     charge: {
//                         amount: subTotal,
//                         currency: ticketCategory?.basePrice?.currency ?? 'CAD',
//                     },
//                 });
//             }
//
//             const preExistingServiceFee = fees.find((fee) => fee.id === '011');
//
//             if (preExistingServiceFee) {
//                 preExistingServiceFee.charge.amount += serviceFee;
//             } else {
//                 fees.push({
//                     id: '011',
//                     order: 1,
//                     name: 'Service Fee',
//                     charge: {
//                         amount: serviceFee,
//                         currency: ticketCategory?.serviceFee?.currency ?? 'CAD',
//                     },
//                 });
//             }
//
//             const preExistingProcessingFee = fees.find((fee) => fee.id === '021');
//
//             if (preExistingProcessingFee) {
//                 preExistingProcessingFee.charge.amount += processingFee;
//             } else {
//                 fees.push({
//                     id: '021',
//                     order: 99,
//                     name: 'Processing Fee',
//                     charge: {
//                         amount: processingFee,
//                         currency: ticketCategory?.processingFee?.currency ?? 'CAD',
//                     },
//                 });
//             }
//         }
//     });
//
//     const total = fees.reduce((acc, item) => acc + item.charge.amount, 0);
//
//     const orderedFees = fees.sort((a, b) => a.order - b.order);
//
//     return {
//         fees: orderedFees,
//         total,
//     };
// };

// export const returnSelectedTicketCategories = (
//     selectedTickets: selectedTickets,
//     ticketCategories: TicketCategoryObject[]
// ) => {
//     return Object.keys(selectedTickets).map((categoryId) => {
//         const quantitySelected = selectedTickets[categoryId];
//         const category = ticketCategories.find((cat) => (cat.categoryId as string) === categoryId);
//
//         return {
//             ...category,
//             quantitySelected: quantitySelected,
//         };
//     });
// };

export const formatTag = (tag: string | null | undefined) =>{
    return tag?.replace(/([A-Z])/g, ' $1') // insert a space before all capital letters
        .replace(/^./, (str) => str.toUpperCase()); // capitalize the first letter
}

export const formatPrice = (price: number, currency = 'CAD') => {
    return Dinero({ amount: price, currency: currency as Currency })
        .setLocale(currency === 'USD' ? 'en-US' : 'en-CA')
        .toFormat();
}