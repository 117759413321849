import Logo from '../../assets/poweredByLogo.png';

import { Grid } from '@mui/material';
import { Skeleton, Typography, Box } from '@mui/joy';
import { ThemeProvider } from '../../ThemeProvider';
import { StyledHR } from '../common/styled/StaticElementsJoy.styled';

export default function OrderSummaryLoading() {
    return (
        <ThemeProvider>
            <>
                <header>
                    <Box sx={{ mb: '60px' }}>
                        <Skeleton
                            width={194}
                            height={17}
                            variant="rectangular"
                            animation="wave"
                            sx={{ mb: '30px' }}
                        />
                    </Box>
                    <Box sx={{ mb: '40px', display: 'flex', alignItems: 'center' }}>
                        <Skeleton
                            width="100%"
                            height={25}
                            variant="rectangular"
                            animation="wave"
                            sx={{ mb: '30px' }}
                        />
                    </Box>
                    <Typography
                        component={'h1'}
                        sx={{ mb: '30px', textAlign: 'left', fontWeight: 600, fontSize: 36 }}
                    >
                        Order Summary
                    </Typography>
                </header>
                <Grid container spacing={2}>
                    <Grid item lg={7} sm={12} xs={12}>
                        <Skeleton
                            width="100%"
                            height={235}
                            variant="rectangular"
                            animation="wave"
                            sx={{ mb: '30px' }}
                        />
                        <Skeleton
                            width="100%"
                            height={84}
                            variant="rectangular"
                            animation="wave"
                            sx={{ mb: '30px' }}
                        />
                        <Skeleton
                            width="40%"
                            height={100}
                            variant="rectangular"
                            animation="wave"
                            sx={{ mb: '15px' }}
                        />
                        <Skeleton
                            width="30%"
                            height={20}
                            variant="rectangular"
                            animation="wave"
                            sx={{ mb: '30px' }}
                        />
                        <StyledHR style={{ marginBottom: '30px' }} />
                        <Skeleton
                            width="60%"
                            height={40}
                            variant="rectangular"
                            animation="wave"
                            sx={{ mb: '30px' }}
                        />
                        <Skeleton
                            width="100%"
                            height={270}
                            variant="rectangular"
                            animation="wave"
                            sx={{ mb: '30px' }}
                        />
                        <StyledHR style={{ marginBottom: '30px' }} />
                    </Grid>
                    <Grid item lg={5} sm={12} xs={12}>
                        <Skeleton
                            width="100%"
                            height={416}
                            variant="rectangular"
                            animation="wave"
                            sx={{ mb: '15px' }}
                        />
                        <div>
                            <img
                                src={Logo}
                                alt="Powered by AdmitONE"
                                style={{ width: '100%', maxWidth: '150px' }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </>
        </ThemeProvider>
    );
}
