import { useState, useEffect, useContext, useCallback, useMemo } from 'react';

import { Box, Typography, Modal, Stack } from '@mui/joy';
import { StyledBorderButton, StyledButton } from '../common/styled/ActionablesJoy.styled.tsx';
import { StyledModalBox } from './paymentPageJoy.styled.tsx';
import Link from '@mui/joy/Link';

import { margin1, font4 } from '../../utils/sharedStyleVariables.ts';

import { EVENTS_URL } from '../../config/index.ts';
import { CartContext } from '../providers/cart.tsx';
import { useHistory } from 'react-router-dom';

interface PaymentTimerProps {
    organizerPrimaryColor: string;
}

export default function PaymentTimer({ organizerPrimaryColor }: PaymentTimerProps) {
    const history = useHistory();
    const { currentEventId, cart, resetCart } = useContext(CartContext);

    const calculateTimeLeft = useCallback(() => {
        if (!cart?.hold?.expiresAt) {
            return [0, 0];
        }

        const difference = new Date(cart.hold.expiresAt).getTime() - new Date().getTime();

        if (difference <= 0) {
            return [0, 0];
        }

        return [
            Math.floor((difference / (1000 * 60)) % 60), // minutes
            Math.floor((difference / 1000) % 60), // seconds
        ];
    }, [cart?.hold?.expiresAt]);

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    const [isExpired, setIsExpired] = useState(Math.max(...timeLeft) === 0);

    const timeLeftString = useMemo(() => {
        if (Math.max(...timeLeft) === 0) {
            return '--:--';
        }

        const minutes = timeLeft[0].toString().padStart(2, '0');
        const seconds = timeLeft[1].toString().padStart(2, '0');

        return `${minutes}:${seconds}`;
    }, [timeLeft]);

    useEffect(() => {
        if (!cart?.hold?.expiresAt) {
            return;
        }

        const timer = setInterval(() => {
            const updatedTimeLeft = calculateTimeLeft();
            setTimeLeft(updatedTimeLeft);

            if (!isExpired && Math.max(...updatedTimeLeft) === 0) {
                setIsExpired(true);
                clearInterval(timer);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [cart?.hold?.expiresAt, isExpired]);

    const cancelCheckout = async () => {
        resetCart(currentEventId as string);
        history.push(`/${currentEventId}`);
    };

    return (
        <>
            <Modal open={isExpired} onClose={cancelCheckout}>
                <StyledModalBox>
                    <Stack>
                        <Typography
                            sx={{ fontWeight: 500, fontSize: '24px', marginBottom: '20px' }}
                        >
                            Sorry your cart has expired
                        </Typography>
                        <Box
                            sx={{
                                alignSelf: 'flex-end',
                                display: 'flex',
                                alignItems: 'flex-start',
                            }}
                        >
                            <Link href={EVENTS_URL}>
                                <StyledBorderButton
                                    sx={{ marginTop: '0 !important', marginRight: '10px' }}
                                    backgroundcolor={organizerPrimaryColor}
                                >
                                    Browse events
                                </StyledBorderButton>
                            </Link>
                            <StyledButton
                                backgroundcolor={organizerPrimaryColor}
                                onClick={cancelCheckout}
                            >
                                Re-select tickets
                            </StyledButton>
                        </Box>
                    </Stack>
                </StyledModalBox>
            </Modal>
            <Box
                sx={{
                    mb: margin1,
                    height: '54px',
                    backgroundColor: organizerPrimaryColor,
                    width: '100%',
                    marginTop: '0',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography sx={{ fontSize: font4, color: '#FFFFFF', fontWeight: 500 }}>
                    Time remaining: {timeLeftString}
                </Typography>
            </Box>
        </>
    );
}
