import { OrderSummaryEventQuery, TicketCategoryObject } from '../../graphql/graphql';
import { dateFormatter, imageFormatter, timeFormatter } from '../../utils/formatter';
import { TicketCatHelper, ticketCounterIssued } from '../../utils/ticketCounter';
import { useContext, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import LogoGrey from '../../assets/LogoGrey.svg';

import TicketCategoryCardJoy from './ticketCategoryCardJoy.component';
import CheckoutCard from './checkoutCardJoy.component';
import { Grid } from '@mui/material';
import { Box, Stack, Typography } from '@mui/joy';
import { ThemeProvider } from '../../ThemeProvider';
import { StyledEventTitle } from '../common/styled/StaticElementsJoy.styled';
import BackToTopButtonJoy from '../common/BackToTopJoy.component';
import CodeToUnlockJoy from './codeToUnlockJoy.component';
import AddonCategoryCardJoy from './addonCategoryCardJoy.component.tsx';
import { CartContext } from '../providers/cart.tsx';

const ORDER_TICKET_LIMIT = 20;

interface OrderSummaryLoadedProps {
    event: OrderSummaryEventQuery['event'];
    organizerPrimaryColor: string;
    organizerSecondaryColor: string;
    setErrorAlert: (err: string | null) => void;
}

export default function OrderSummaryLoadedJoy({
    event,
    organizerPrimaryColor,
    organizerSecondaryColor,
    setErrorAlert,
}: OrderSummaryLoadedProps) {
    const { cart, isUnlocked, hasLocks } = useContext(CartContext);
    const { quantities } = cart;

    const [overallLimit, setOverallLimit] = useState<boolean>(false);
    const [ticketsOverLimit, setTicketsOverLimit] = useState<boolean>(false);
    const [currentTotalTicketsSelected, setCurrentTotalTicketsSelected] = useState<number>(0);

    const { totalIssued } = ticketCounterIssued(event?.ticketCategories as TicketCatHelper[]);

    const maxTicketQuantity = useMemo(() => {
        if (event?.eventCapacity && event?.ticketCategories) {
            return event.eventCapacity - totalIssued < ORDER_TICKET_LIMIT
                ? Math.max(event.eventCapacity - totalIssued, 0)
                : ORDER_TICKET_LIMIT;
        } else {
            return ORDER_TICKET_LIMIT;
        }
    }, [event]);

    useEffect(() => {
        let overallTotal = 0;

        for (const key in quantities) {
            if (key in quantities) {
                overallTotal += quantities[key];
            }
        }

        setOverallLimit(overallTotal === maxTicketQuantity);
        setTicketsOverLimit(overallTotal > maxTicketQuantity);
        setCurrentTotalTicketsSelected(overallTotal);
    }, [quantities]);

    const remainingTickets =
        event && event.eventCapacity ? Number(event.eventCapacity) - totalIssued : null;

    const visibileTicketCategories = event?.ticketCategories
        ? event?.ticketCategories.filter(
              (cat) =>
                  cat?.visibility?.toUpperCase() !== 'HIDDEN' &&
                  !cat?.status?.includes('Sale Ended') &&
                  !cat?.status?.includes('Scheduled')
          )
        : [];

    const renderPurchaseUnavailableMessage = () => {
        if (event?.status === 'Draft') {
            return 'Draft event';
        }
        if (event?.suspension?.indefinite) {
            return 'Event suspended';
        }
        if (event?.status === 'Cancelled') {
            return 'Event cancelled';
        }
        if (eventEnded()) {
            return 'Event ended';
        }
        if (visibileTicketCategories.length === 0) {
            return (
                <Stack>
                    <Box>
                        <img
                            src={LogoGrey}
                            alt="Logo Grey"
                            style={{ width: '100%', maxWidth: '35px' }}
                        />
                    </Box>
                    <Box>No tickets to show</Box>
                </Stack>
            );
        }
    };

    const hasAvailableTickets = visibileTicketCategories.length > 0;
    const isDraft = event?.status === 'Draft';
    const isCancelled = event?.status === 'Cancelled';
    const isSuspended = event?.suspension?.indefinite;

    const eventEnded = () => {
        if (event?.endDate) {
            const currentDate = dayjs(new Date()).unix();
            const endDate = dayjs(event.endDate).unix();
            return currentDate > endDate;
        } else {
            return false;
        }
    };

    return (
        <ThemeProvider>
            <>
                <BackToTopButtonJoy organizerPrimaryColor={organizerPrimaryColor} />

                {event && (
                    <Grid container spacing={2} columnSpacing={15}>
                        <Grid item md={7} xs={12}>
                            <Stack>
                                <Box
                                    component="picture"
                                    sx={{
                                        width: '100%',
                                        display: 'inline-block',
                                        marginBottom: 3,
                                        img: {
                                            borderRadius: 4,
                                        },
                                    }}
                                >
                                    {/* Whichever source matches the media query will be used */}
                                    <source
                                        srcSet={`${imageFormatter(
                                            event.eventImage
                                        )}?w=1150&h=400&fit=fill&fill=blur`}
                                        media="(min-width: 1150px)"
                                    />
                                    <source
                                        srcSet={`${imageFormatter(
                                            event.eventImage
                                        )}?w=800&h=400&fit=fill&fill=blur`}
                                        media="(min-width: 800px)"
                                    />
                                    <Box
                                        component="img"
                                        src={`${imageFormatter(
                                            event.eventImage
                                        )}?w=500&h=300&fit=fill&fill=blur`}
                                        sx={{ width: '100%' }}
                                    />
                                </Box>

                                <Box sx={{ marginBottom: 1 }}>
                                    <StyledEventTitle level={'body-lg'}>
                                        {event.title}
                                    </StyledEventTitle>
                                </Box>

                                <Stack sx={{ marginBottom: 1 }}>
                                    <Typography level={'body-md'} sx={{ fontWeight: 600 }}>
                                        {dateFormatter(
                                            new Date(event.startDate),
                                            new Date(event.endDate),
                                            event.timezone ||
                                                Intl.DateTimeFormat().resolvedOptions().timeZone
                                        )}
                                    </Typography>
                                    <Typography level={'body-md'} sx={{ fontWeight: 600 }}>
                                        {timeFormatter(
                                            new Date(event.startDate),
                                            new Date(event.endDate),
                                            event.timezone ||
                                                Intl.DateTimeFormat().resolvedOptions().timeZone
                                        )}
                                    </Typography>
                                </Stack>

                                <Stack sx={{ marginBottom: 1 }}>
                                    {event.place === 'address' ? (
                                        <>
                                            {event.address?.businessName ? (
                                                <Stack>
                                                    <Typography
                                                        level={'body-md'}
                                                        sx={{ fontWeight: 600 }}
                                                    >
                                                        {event.address.businessName}
                                                    </Typography>
                                                    <Typography
                                                        level={'body-md'}
                                                        sx={{ fontWeight: 600 }}
                                                    >
                                                        {event.address?.street},{' '}
                                                        {event.address?.city}{' '}
                                                        {event.address?.region}
                                                    </Typography>
                                                </Stack>
                                            ) : (
                                                <Typography
                                                    level={'body-md'}
                                                    sx={{ fontWeight: 600 }}
                                                >
                                                    {event.address?.formattedAddress}
                                                </Typography>
                                            )}
                                        </>
                                    ) : (
                                        <Typography level={'body-md'} sx={{ fontWeight: 600 }}>
                                            Online Event
                                        </Typography>
                                    )}
                                </Stack>

                                {event.presentedBy && (
                                    <Box sx={{ marginBottom: 2 }}>
                                        <Typography level={'body-md'} sx={{ fontWeight: 600 }}>
                                            Organized by{' '}
                                            <Typography
                                                sx={{
                                                    color: organizerPrimaryColor,
                                                    fontWeight: 400,
                                                }}
                                            >
                                                {event.presentedByName}
                                            </Typography>
                                        </Typography>
                                    </Box>
                                )}
                                <Box sx={{ mt: 4 }}>
                                    {hasLocks && !isCancelled && !eventEnded() && !isSuspended && (
                                        <CodeToUnlockJoy
                                            organizerPrimaryColor={organizerPrimaryColor}
                                        />
                                    )}
                                </Box>

                                {(!hasAvailableTickets ||
                                    isCancelled ||
                                    isDraft ||
                                    eventEnded() ||
                                    isSuspended) && (
                                    <Typography
                                        sx={{
                                            fontSize: '24px',
                                            fontWeight: '400',
                                            color: 'rgba(145, 158, 171, 1)',
                                            width: '100%',
                                            textAlign: 'center',
                                            marginBottom: '30px',
                                        }}
                                    >
                                        {renderPurchaseUnavailableMessage()}
                                    </Typography>
                                )}

                                {hasAvailableTickets &&
                                    !isCancelled &&
                                    !eventEnded() &&
                                    !isSuspended && (
                                        <Stack sx={{ marginBottom: 2 }}>
                                            <Typography
                                                fontWeight={600}
                                                fontSize={'large'}
                                                sx={{ mb: 2 }}
                                            >
                                                Ticket Types
                                            </Typography>
                                            {visibileTicketCategories
                                                .map((cat, index) => {
                                                    if (!cat) {
                                                        return null;
                                                    }

                                                    const [unlocked, unlockCodes] = isUnlocked(
                                                        cat.categoryId
                                                    );

                                                    if (!unlocked) {
                                                        return null;
                                                    }

                                                    return (
                                                        <TicketCategoryCardJoy
                                                            key={cat?.categoryId}
                                                            id={`ticket_${index}`}
                                                            unlockCode={unlockCodes}
                                                            ticketCategory={
                                                                cat as TicketCategoryObject
                                                            }
                                                            selectedTickets={quantities}
                                                            timezone={event.timezone as string}
                                                            organizerPrimaryColor={
                                                                organizerPrimaryColor
                                                            }
                                                            organizerSecondaryColor={
                                                                organizerSecondaryColor
                                                            }
                                                            overallLimit={overallLimit}
                                                            remainingTickets={remainingTickets}
                                                            currentTotalTicketsSelected={
                                                                currentTotalTicketsSelected
                                                            }
                                                        />
                                                    );
                                                })
                                                .filter(Boolean)}
                                        </Stack>
                                    )}
                            </Stack>
                            <Stack>
                                {hasAvailableTickets &&
                                    !isCancelled &&
                                    !eventEnded() &&
                                    !isSuspended && (
                                        <Stack>
                                            {event?.addonCategories &&
                                                event.addonCategories.length > 0 && (
                                                    <Typography
                                                        fontWeight={600}
                                                        fontSize={'large'}
                                                        sx={{ mb: 2 }}
                                                    >
                                                        Add-ons
                                                    </Typography>
                                                )}
                                            <Stack sx={{ marginBottom: '30px' }}>
                                                {event?.addonCategories
                                                    ?.map((cat) => {
                                                        if (!cat) {
                                                            return null;
                                                        }

                                                        if (cat.isHidden) {
                                                            return null;
                                                        }

                                                        const [unlocked, unlockCodes] = isUnlocked(
                                                            cat.categoryId
                                                        );

                                                        if (!unlocked) {
                                                            return null;
                                                        }

                                                        return (
                                                            <AddonCategoryCardJoy
                                                                event={event}
                                                                key={cat?.categoryId}
                                                                eventId={event.id}
                                                                addonCategory={cat}
                                                                organizerPrimaryColor={
                                                                    organizerPrimaryColor
                                                                }
                                                                unlockCodesUsed={unlockCodes}
                                                            />
                                                        );
                                                    })
                                                    .filter(Boolean)}
                                            </Stack>
                                        </Stack>
                                    )}
                            </Stack>
                        </Grid>
                        <Grid item md={5} xs={12}>
                            <CheckoutCard
                                organizerPrimaryColor={organizerPrimaryColor}
                                organizerSecondaryColor={organizerSecondaryColor}
                                event={event!}
                                soldOut={maxTicketQuantity === 0}
                                currency={event?.currency || 'CAD'}
                                setErrorAlert={setErrorAlert}
                                disablePurchase={Boolean(
                                    !hasAvailableTickets ||
                                        isCancelled ||
                                        isDraft ||
                                        eventEnded() ||
                                        isSuspended ||
                                        ticketsOverLimit
                                )}
                            />
                        </Grid>
                    </Grid>
                )}
            </>
        </ThemeProvider>
    );
}
