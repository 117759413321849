import { Typography, Stack, Box, FormLabel, FormControl, Input } from '@mui/joy';
import { Dispatch, FunctionComponent, SetStateAction, useContext, useEffect, useRef } from 'react';
import { StyledCreditCardInput } from '../payment/paymentPageJoy.styled';
import { SROnlyLabel } from '../common/styled/StaticElements.styled';
import { AmexSVG, MCSVG, VisaSVG } from '../../utils/svgIcons';
import { SafeChargeCardFieldType } from '../../types/safeCharge';
import { SafeChargeContext } from '../common/safecharge.provider';

interface SafeChargeCCProps {
    paymentFields: { cardHN: string };
    setPaymentFields: Dispatch<SetStateAction<{ cardHN: string }>>;
    setSafeChargeCardField: Dispatch<SetStateAction<SafeChargeCardFieldType | null>>;
    organizerPrimaryColor: string;
}

export const SafeChargeCC: FunctionComponent<SafeChargeCCProps> = ({
    paymentFields,
    setPaymentFields,
    setSafeChargeCardField,
    organizerPrimaryColor,
}) => {
    const safeCharge = useContext(SafeChargeContext);
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (ref?.current && ref.current.querySelector('div') === null) {
            const safeChargeFields = safeCharge!.fields({
                fonts: [{ cssUrl: '' }],
                locale: 'en',
            });

            const scFields = safeChargeFields.create('card', {});

            scFields.attach(ref.current);
            setSafeChargeCardField(scFields);
        }
    }, [ref?.current]);

    const requiredSymbol = () => {
        return (
            <span style={{color:'rgba(196, 28, 28, 1)'}}>*</span>
        )
    }

    return (
        <>
            <Stack>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mb: 2,
                        mt: 3,
                    }}
                >
                    <Typography
                        level={'body-md'}
                        sx={{
                            textAlign: 'left',
                            fontWeight: 600,
                        }}
                    >
                        Card Information
                    </Typography>
                    <Box>
                        <VisaSVG />
                        <MCSVG />
                        <AmexSVG />
                    </Box>
                </Box>
                <Stack sx={{ marginBottom: 2 }}>
                    <FormControl id="cardholderName">
                        <>
                            <FormLabel sx={{ fontWeight: 500 }}>Cardholder Name{requiredSymbol()}</FormLabel>
                            <Input
                                id="cardHM"
                                name="cardHN"
                                size="md"
                                placeholder="Cardholder Name"
                                value={paymentFields.cardHN}
                                sx={{
                                    '&:focus-within::before': {
                                        boxShadow: `inset 0px 0px 1px 1px ${organizerPrimaryColor}!important`,
                                    },
                                }}
                                onChange={(e) =>
                                    setPaymentFields({
                                        ...paymentFields,
                                        [e.target.name]: e.target.value,
                                    })
                                }
                            />
                        </>
                    </FormControl>
                </Stack>
                <Stack>
                    <FormLabel>Card Number{requiredSymbol()}</FormLabel>
                    <SROnlyLabel htmlFor="card-field">Card Details</SROnlyLabel>
                    <StyledCreditCardInput
                        ref={ref}
                        id="card-field"
                        className="focus:ring-green-500 focus:border-green-500 sm:text-sm"
                        setcolor={organizerPrimaryColor}
                    />
                </Stack>
            </Stack>
        </>
    );
};
