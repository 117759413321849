import { Typography, Box, Container, Skeleton } from '@mui/joy';
import { StyledHR } from '../common/styled/StaticElementsJoy.styled';
import {
    margin1,
    margin2,
    margin3,
    font1,
    font2,
    font3,
    inputHeight1,
    inputHeight2,
    inputMargin1,
} from '../../utils/sharedStyleVariables';
import { ThemeProvider } from '../../ThemeProvider';

export default function PaymentLoading() {
    return (
        <ThemeProvider>
            <>
                <Box sx={{ mb: margin1 }}>
                    <Skeleton
                        width={'100%'}
                        height={'54px'}
                        variant="rectangular"
                        animation="wave"
                    />
                </Box>
                <Container sx={{ paddingBottom: '60px' }}>
                    <header>
                        <Box sx={{ mb: margin2 }}>
                            <Skeleton
                                width={194}
                                height={17}
                                variant="rectangular"
                                animation="wave"
                            />
                        </Box>
                        <Box sx={{ mb: '40px', display: 'flex', alignItems: 'center' }}>
                            <Skeleton
                                width="100%"
                                height={25}
                                variant="rectangular"
                                animation="wave"
                                sx={{ mb: margin2 }}
                            />
                        </Box>
                        <Typography
                            level="body-md"
                            sx={{
                                fontSize: font1,
                                mb: margin2,
                                textAlign: 'left',
                                fontWeight: 300,
                            }}
                        >
                            Checkout
                        </Typography>
                    </header>

                    <Box
                        sx={{
                            display: 'flex',
                            gridGap: '10px',
                            width: '100%',
                            flexDirection: { sm: 'row', xs: 'column' },
                        }}
                    >
                        <Skeleton
                            height={'197px'}
                            variant="rectangular"
                            animation="wave"
                            sx={{
                                mb: margin3,
                                maxWidth: '100%',
                                width: { sm: '172px', xs: '100%' },
                            }}
                        />
                        <Box sx={{ width: { sm: 'calc(100% - 170px)', xs: '100%' } }}>
                            <Skeleton
                                width="100%"
                                variant="rectangular"
                                animation="wave"
                                sx={{ mb: margin2, maxWidth: '372px', height: '32px' }}
                            />
                            <Skeleton
                                width="194px"
                                variant="rectangular"
                                animation="wave"
                                sx={{ mb: '5px', maxWidth: '100%', height: '16px' }}
                            />
                            <Skeleton
                                width="194px"
                                variant="rectangular"
                                animation="wave"
                                sx={{ mb: margin3, maxWidth: '100%', height: '16px' }}
                            />
                            <Skeleton
                                width="194px"
                                variant="rectangular"
                                animation="wave"
                                sx={{ mb: '5px', maxWidth: '100%', height: '16px' }}
                            />
                            <Skeleton
                                width="194px"
                                variant="rectangular"
                                animation="wave"
                                sx={{ mb: margin2, maxWidth: '100%', height: '16px' }}
                            />
                            <Skeleton
                                width="194px"
                                variant="rectangular"
                                animation="wave"
                                sx={{ maxWidth: '100%', height: '16px' }}
                            />
                        </Box>
                    </Box>
                    <StyledHR></StyledHR>

                    <Box
                        sx={{
                            display: 'flex',
                            gridGap: '10px',
                            flexDirection: { sm: 'row', xs: 'column-reverse' },
                        }}
                    >
                        {/* Form Fields..................................................... */}
                        <Box sx={{ flex: '1' }}>
                            <Box>
                                <Typography
                                    level="body-md"
                                    sx={{
                                        fontSize: font2,
                                        mb: margin2,
                                        textAlign: 'left',
                                        fontWeight: '400',
                                    }}
                                >
                                    Your Information
                                </Typography>
                            </Box>
                            <Typography
                                level="body-md"
                                sx={{
                                    fontSize: font3,
                                    mb: margin2,
                                    textAlign: 'left',
                                    fontWeight: '400',
                                }}
                            >
                                Full name
                            </Typography>
                            {/* First Name */}
                            <Skeleton
                                height={inputHeight1}
                                width="100%"
                                variant="rectangular"
                                animation="wave"
                                sx={{ maxWidth: '100%', marginBottom: inputMargin1 }}
                            />
                            {/* Last Name */}
                            <Skeleton
                                height={inputHeight1}
                                width="100%"
                                variant="rectangular"
                                animation="wave"
                                sx={{ maxWidth: '100%' }}
                            ></Skeleton>
                            <Typography
                                level="body-md"
                                sx={{
                                    fontSize: font3,
                                    mt: margin2,
                                    mb: margin2,
                                    textAlign: 'left',
                                    fontWeight: '400',
                                }}
                            >
                                Address (Optional)
                            </Typography>
                            {/* Street Address*/}
                            <Skeleton
                                height={inputHeight1}
                                width="100%"
                                variant="rectangular"
                                animation="wave"
                                sx={{ maxWidth: '100%', marginBottom: inputMargin1 }}
                            />
                            {/* Town City */}
                            <Skeleton
                                height={inputHeight1}
                                width="100%"
                                variant="rectangular"
                                animation="wave"
                                sx={{ maxWidth: '100%', marginBottom: inputMargin1 }}
                            />
                            {/* Addresss block grid..................... */}
                            <Box
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: {
                                        sm: 'repeat(2,1fr)',
                                        xs: 'repeat(1,1fr)',
                                    },
                                    gridGap: '10px',
                                }}
                            >
                                {/* Country */}
                                <Skeleton
                                    height={inputHeight1}
                                    width="100%"
                                    variant="rectangular"
                                    animation="wave"
                                    sx={{ maxWidth: '100%' }}
                                />
                                {/* State Province */}
                                <Skeleton
                                    height={inputHeight1}
                                    width="100%"
                                    variant="rectangular"
                                    animation="wave"
                                    sx={{ maxWidth: '100%' }}
                                />

                                {/* Zip */}
                                <Skeleton
                                    height={inputHeight1}
                                    width="100%"
                                    variant="rectangular"
                                    animation="wave"
                                    sx={{ maxWidth: '100%', marginBottom: inputMargin1 }}
                                />
                            </Box>

                            <Typography
                                level="body-md"
                                sx={{
                                    fontSize: font3,
                                    mt: margin2,
                                    mb: margin2,
                                    textAlign: 'left',
                                    fontWeight: '400',
                                }}
                            >
                                Contact Information
                            </Typography>
                            {/* Phone */}
                            <Skeleton
                                height={inputHeight1}
                                width="100%"
                                variant="rectangular"
                                animation="wave"
                                sx={{ maxWidth: '100%', marginBottom: inputMargin1 }}
                            ></Skeleton>
                            {/* Email */}
                            <Skeleton
                                height={inputHeight1}
                                width="100%"
                                variant="rectangular"
                                animation="wave"
                                sx={{ maxWidth: '100%', marginBottom: inputMargin1 }}
                            ></Skeleton>
                            {/* Email Confirm */}
                            <Skeleton
                                height={inputHeight1}
                                width="100%"
                                variant="rectangular"
                                animation="wave"
                                sx={{ maxWidth: '100%', marginBottom: inputMargin1 }}
                            ></Skeleton>
                            <Box>
                                {/* CheckBox................. */}
                                <Skeleton
                                    height={inputHeight2}
                                    width="100%"
                                    variant="rectangular"
                                    animation="wave"
                                    sx={{
                                        maxWidth: '100%',
                                        marginTop: margin2,
                                        marginBottom: inputHeight1,
                                    }}
                                ></Skeleton>
                            </Box>
                            <Box>
                                {/* Submit button................. */}
                                <Skeleton
                                    height="38px"
                                    width="119px"
                                    variant="rectangular"
                                    animation="wave"
                                    sx={{
                                        maxWidth: '100%',
                                        marginTop: margin2,
                                        marginBottom: inputHeight1,
                                    }}
                                ></Skeleton>
                            </Box>
                        </Box>
                        {/* Summary Box................................................. */}
                        <Box sx={{ flex: '1', position: 'relative' }}>
                            <Skeleton
                                height="373.38px"
                                variant="rectangular"
                                animation="wave"
                                sx={{
                                    maxWidth: '100%',
                                    marginBottom: inputHeight1,
                                    position: 'sticky',
                                    top: '0',
                                }}
                            ></Skeleton>
                        </Box>
                    </Box>
                </Container>
            </>
        </ThemeProvider>
    );
}
